import CryptoJS from "crypto-js";
import { doc, getDoc } from "firebase/firestore";
import { shuffleArray } from "../../Global/utils/helperFunctions";
import { fStore } from "../../firebase";
import { getDataFromRealtimeDatabase } from "./FirebaseFunctions";

export function encryptData(data, encryptionKey) {
  if (typeof data === "string") {
    const encryptedBytes = CryptoJS.enc.Utf8.parse(data);
    return CryptoJS.AES.encrypt(encryptedBytes, encryptionKey).toString();
  } else if (Array.isArray(data)) {
    const encryptedArray = data.map((element) => {
      const encryptedBytes = CryptoJS.enc.Utf8.parse(element);
      return CryptoJS.AES.encrypt(encryptedBytes, encryptionKey).toString();
    });
    return encryptedArray;
  } else {
    throw new Error(
      "Unsupported data type. Only strings and arrays are supported."
    );
  }
}

export function decryptData(encryptedData, decryptionKey) {
  if (typeof encryptedData === "string") {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, decryptionKey);
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  } else if (Array.isArray(encryptedData)) {
    const decryptedArray = encryptedData.map((element) => {
      const decryptedBytes = CryptoJS.AES.decrypt(element, decryptionKey);
      return decryptedBytes.toString(CryptoJS.enc.Utf8);
    });
    return decryptedArray;
  } else {
    throw new Error(
      "Unsupported data type. Only strings and arrays are supported."
    );
  }
}

export async function getNRandomDocumentIdsArray({
  realTimeDBKey,
  numberOfDocuments,
}) {
  const AllQuestionsIdList = await getDataFromRealtimeDatabase(realTimeDBKey);
  const questionIds = Object.keys(AllQuestionsIdList);
  console.log("DWaraka check dsa  list questionIds: ", questionIds);
  // Shuffle the array of question IDs
  const shuffledQuestionIds = shuffleArray(questionIds);
  // Select the first n elements from the shuffled array
  const randomQuestionIds = shuffledQuestionIds.slice(0, numberOfDocuments);
  return randomQuestionIds;
}

export async function fetchDocumentsDataArrayFromFireStore({
  collectionName,
  documentIds,
}) {
  try {
    const dataPromises = documentIds.map(async (documentId) => {
      const documentRef = doc(fStore, collectionName, documentId);
      const docSnap = await getDoc(documentRef);

      if (docSnap.exists()) {
        return { [documentId]: docSnap.data() };
      } else {
        console.log(`Document with ID ${documentId} does not exist`);
        return null;
      }
    });

    const documentDataArray = await Promise.all(dataPromises);

    return documentDataArray;
  } catch (error) {
    console.error("Error fetching documents:", error);
    return {};
  }
}

export function formatTimestamp(timestamp) {
  let timestampDate;
  if (typeof timestamp === "object") {
    if (timestamp.seconds && timestamp.nanoseconds) {
      // If it's in the second form
      const seconds = timestamp.seconds;
      const milliseconds = timestamp.nanoseconds / 1e6; // Convert nanoseconds to milliseconds
      timestampDate = new Date(seconds * 1000 + milliseconds);
    } else {
      timestampDate = new Date(timestamp);
    }
  } else {
    // Handle unknown format or invalid input
    return "Invalid timestamp format";
  }

  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = timestampDate.toLocaleString("en-US", options);

  const optionsBig = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const formattedDateBig = timestampDate.toLocaleString("en-US", optionsBig);

  return {
    formattedDate,
    formattedDateBig,
  };
}

export const mapScoreToNewRange = (score, oldMin, oldMax, newMin, newMax) => {
  const oldRange = oldMax - oldMin;
  const newRange = newMax - newMin;
  return ((score - oldMin) * newRange) / oldRange + newMin;
};
