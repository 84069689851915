import { get, ref as ref1, update } from "firebase/database";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
export async function findIfValidBusinessUser(email, realTimeDBPath) {
  console.log("DWaraka Siri: ", email, realTimeDBPath);

  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const emailList = snapshot.val() || [];
      return emailList.find((item) => item.email === email);
    } else {
      console.error("No data found at given location");
      return false;
    }
  } catch (error) {
    console.error("Error finding email in Realtime DB:", error);
    return false;
  }
}

export async function getAllBusinessCandidatesList(realTimeDBPath) {
  console.log("DWaraka Siri: ", realTimeDBPath);
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const emailList = snapshot.val() || [];
      // console.log("Dwarkaa Siri emailList: ", emailList);
      return emailList;
    } else {
      console.error("No data found at given location");
      return false;
    }
  } catch (error) {
    console.error("Error finding email in Realtime DB:", error);
    return false;
  }
}

export async function addNewAdminDataForCompany({
  realTimeDBPath,
  newCandidate,
}) {
  try {
    console.log("DWaraka Siri realTimeDBPath: ", realTimeDBPath, newCandidate);
    const dbRef = ref1(db, realTimeDBPath);
    const snapshot = await get(dbRef);
    let updatedData = {};

    if (snapshot.exists()) {
      // If data already exists, merge new candidates while ensuring uniqueness
      updatedData = snapshot.val();
    }

    if (!Array.isArray(updatedData)) {
      updatedData = [];
    }

    // Check if there is already a candidate with the same email
    const existingCandidate = updatedData.find(
      (candidate) => candidate.email === newCandidate.email
    );

    if (existingCandidate) {
      toast.warning(`Entry for ${newCandidate.email} already exists.`);
      console.log(`Candidate with email ${newCandidate.email} already exists.`);
      return; // Exit function if candidate with similar email exists
    }

    // Push the new candidate to the array
    updatedData.push(newCandidate);

    // Convert the array to an object with numeric keys
    const indexedData = updatedData.reduce((acc, cur, index) => {
      acc[index] = cur;
      return acc;
    }, {});

    // Update the database with the indexedData object
    await update(dbRef, indexedData);
    toast.success(`${newCandidate.email} added successfully.`);
  } catch (error) {
    console.error("Error updating value:", error);
  }
}
