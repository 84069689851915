import { get, ref as ref1, set } from "firebase/database";
import { db } from "../../../firebase";
import {
  arrayToObjectWithPrivateAccess,
  removePrivateAccessVaraible,
} from "../stringExtensionFunction";
export async function findIfValidBusinessUser({
  email,
  realTimeDBPath,
  categoryName,
}) {
  // console.log("DWaraka Siri: ", email, realTimeDBPath, categoryName);
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      let emailList = snapshot.val() || [];
      // console.log("Dwarkaa Siri email: ", email);
      let thisUserList = removePrivateAccessVaraible(emailList[categoryName]);
      // console.log("Dwarkaa Siri emailList: ", thisUserList);
      return thisUserList.find((item) => item.email === email);
    } else {
      console.error("No data found at given location");
      return false;
    }
  } catch (error) {
    console.error("Error finding email in Realtime DB:", error);
    return false;
  }
}
export async function fetchAllBusinessPageData({
  realTimeDBPath,
  categoryName,
}) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const emailList = snapshot.val() || [];

      // console.log("Dwarkaa Siri emailList: ", emailList[categoryName]);
      return emailList[categoryName];
    } else {
      console.error("No data found at given location");
      return false;
    }
  } catch (error) {
    console.error("Error finding email in Realtime DB:", error);
    return false;
  }
}
export async function editCandidateStatusInRealTimeDatabase({
  email,
  realTimeDBPath,
  variableName,
  updatedValue,
  categoryName,
}) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const keyRef = ref1(db, realTimeDBPath);
      const snapshot = await get(keyRef);

      if (snapshot.exists()) {
        const candidatesList = snapshot.val() || [];
        const isPrivateAccess = candidatesList[categoryName]?.isPrivateAccess;
        const candidatesArray = removePrivateAccessVaraible(
          candidatesList[categoryName]
        );
        // console.log("DWaraka Siri candidatesArray: ", candidatesArray);
        const index = candidatesArray.findIndex((item) => item.email === email);
        // console.log("DWaraka Siri: index: ", index);
        if (index !== -1) {
          candidatesList[categoryName][index][variableName] = updatedValue;
          await set(ref1(db, realTimeDBPath), candidatesList);
          // console.log(
          //   "DWaraka Siri: updated candidatesList: ",
          //   candidatesList[categoryName]
          // );
          resolve("Status updated successfully.");
        } else {
          const newCandidate = { email: email, [variableName]: updatedValue };
          candidatesArray.push(newCandidate);
          let updatedCategotyData = arrayToObjectWithPrivateAccess(
            candidatesArray,
            isPrivateAccess
          );
          candidatesList[categoryName] = updatedCategotyData;
          await set(ref1(db, realTimeDBPath), candidatesList);
          console.log("Candidate not found in the database.");
        }
      } else {
        // If the userId does not exist, reject with an error
        reject(new Error("Candidate not found in the database."));
      }
    } catch (error) {
      console.error("Error editing value:", error);
      reject(error);
    }
  });
}
