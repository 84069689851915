/* eslint-disable no-unused-vars */
import { getAnalytics } from "@firebase/analytics";
import { avatarVoiceMappings } from "../../Global/components/InterviewBot/InterviewBotConstants/InterviewBotConstants";
import {
  eventTypes,
  logAnalyticsErrorEvent,
} from "../../Global/utils/Analytics/firebaseAnalytics";
import { playAudio } from "../../Global/utils/speechSynthesizer";
import { capitalizeFirstLetter } from "../../Global/utils/stringExtensionFunctions";
import { decryptData, encryptData } from "./GeneralHelperFunctions";
const analytics = getAnalytics();

export async function performAPIRequest({ endPoint, body }) {
  console.log("Dwaraka making an API call");
  // eslint-disable-next-line no-undef
  return fetch(`${process.env.REACT_APP_BACKEND_BASEURL}/${endPoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
}

export function handleSendUserResponse({
  makeNetworkCall,
  avatarName,
  setLoading,
  setConversation,
  setTimeStamps,
  setCode,
  isThisCodeEvaluation,
  getTimerTime,
  handleSpeechStart,
  handleSpeechEnd,
  setCurrentPlayingAudio,
  inputDataForSystemPrompt,
  roundTitle,
  userUid,
  interviewId,
  firestoreCollectionId,
  userName,
}) {
  return async ({ latestConversation, latestTimestamps }) => {
    makeNetworkCall({
      funcWithInternet: async () => {
        setLoading(true);
        console.log("Input data for system prompt: ", inputDataForSystemPrompt);
        try {
          let response;
          for (let i = 0; i < 2; i++) {
            response = await performAPIRequest({
              endPoint: "forward",
              body: JSON.stringify({
                roundType: roundTitle.toLowerCase(),
                conversation: latestConversation,
                stream: true,
                maxTokens: isThisCodeEvaluation ? 1000 : 256,
                userUid,
                userName: capitalizeFirstLetter(userName),
                interviewId,
                firestoreCollectionId,
                inputDataForSystemPrompt: encryptData(
                  inputDataForSystemPrompt,
                  interviewId
                ),
                languageCode: avatarVoiceMappings[avatarName]["languageCode"],
                ssmlGender: avatarVoiceMappings[avatarName]["ssmlGender"],
              }),
            });
            if (response.ok) break;
          }
          if (!response.ok) {
            handleError(latestTimestamps, response);
            return;
          }

          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const mediaSource = audioContext.createMediaElementSource(
            new Audio()
          );
          const mediaStreamDestination =
            audioContext.createMediaStreamDestination();
          mediaSource.connect(mediaStreamDestination);

          const reader = response.body.getReader();
          var audioURLs = [];
          var totalMessage = "";
          // eslint-disable-next-line no-constant-condition
          var chuckMsgTillNow = "";
          const pump = async () => {
            // eslint-disable-next-line no-constant-condition
            while (true) {
              const { value, done } = await reader.read();
              if (done) {
                console.log("Stream complete");
                handleSpeechEnd({ isStreamEnded: true });
                return;
              }
              // console.log("Received value:", value);
              // Convert Uint8Array to string
              const stringValue = new TextDecoder().decode(value);
              // console.log("Received stringValue:", stringValue);
              chuckMsgTillNow += stringValue;
              // console.log(" chuckMsgTillNow:", chuckMsgTillNow);
              // Split the string value into individual chunks
              const chunks = chuckMsgTillNow.split("}{");
              for (let chunk of chunks) {
                // Reconstruct the JSON string for each chunk
                if (chunks.length > 1 && chunk == chunks[0]) {
                  chunk = chunk + "}";
                } else if (
                  chunks.length > 1 &&
                  chunk == chunks[chunks.length - 1]
                ) {
                  chunk = "{" + chunk;
                  // chuckMsgTillNow += chunk;
                } else if (chunks.length > 1) {
                  chunk = "{" + chunk + "}";
                }

                if (
                  chunks.length > 1 &&
                  chunk == "{" + chunks[chunks.length - 1] &&
                  chunk.charAt(chunk.length - 1) !== "}"
                ) {
                  chuckMsgTillNow += chunk;
                }

                try {
                  const { text, audio } = JSON.parse(chunk);
                  chuckMsgTillNow = "";
                  console.log("Text response: ", text);
                  totalMessage += text;

                  const audioBuffer = new Uint8Array(audio.data);
                  const audioData = audioBuffer.reduce((data, byte) => {
                    data.push(byte);
                    return data;
                  }, []);

                  const audioBlob = new Blob([new Uint8Array(audioData)], {
                    type: "audio/mpeg",
                  });
                  const audioURL = URL.createObjectURL(audioBlob);

                  audioURLs.push(audioURL);
                  console.log(
                    "Text response: totalMessage  in setTimeout: ",
                    totalMessage
                  );
                  updateConversationArrays(
                    totalMessage,
                    setConversation,
                    setTimeStamps,
                    latestTimestamps,
                    isThisCodeEvaluation
                  );
                  handleSpeechStart();
                  await playAudio(audioURL, setCurrentPlayingAudio);
                  handleSpeechEnd({ isStreamEnded: false });
                  setLoading(false);
                } catch {
                  // chuckMsgTillNow += chunk;
                  console.log("Error json parsing value");
                }
              }
            }
          };

          pump();
          setLoading(false);

          if (!reader) {
            handleError(latestTimestamps, response);
            return;
          }
          return totalMessage;
        } catch (error) {
          handleError(latestTimestamps, error);
          return;
        }
      },
    });
  };

  function handleError(latestTimestamps, response) {
    updateConversationArrays(
      "Internal Server Error, please retry",
      setConversation,
      setTimeStamps,
      latestTimestamps,
      isThisCodeEvaluation
    );
    handleSpeechEnd({ isStreamEnded: true });
    setLoading(false);
    logAnalyticsErrorEvent({
      eventType: eventTypes.gpt_api_failure,
      eventData: {
        statusCode: response.status,
        endPoint: `forward`,
        api_call_type: "conversation",
      },
    });
    console.log("Error:", response);
  }
}

export function handleSendAlertMessageToSystem({
  conversation,
  setConversation,
  setTimeStamps,
}) {
  return async () => {
    try {
      const response = await fetch(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_BACKEND_BASEURL}/forward`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            conversation: conversation.concat([
              {
                role: "system",
                content:
                  "Please inform the candidate that there is only 1 minute left in this round of interview and its time to wrap up this round. Do not ask anymore interview questions. Ask the candidate if he has any doubts or need any clarifications and Wrapup this round with some closing feedback or comments.",
              },
            ]),
            roundType: "",
            stream: true,
            userUid: "",
            interviewId: "",
            firestoreCollectionId: "",
            maxTokens: 1,
            inputDataForSystemPrompt: "",
          }),
        }
      );

      const reader = response.body?.getReader();
      if (!reader) {
        console.error("Error: fail to read data from response");
        return;
      }
      let totalString = "";

      let currentSentence = "";

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const textDecoder = new TextDecoder("utf-8");
        const chunk = textDecoder.decode(value);

        let deltaText = "";
        for (const line of chunk.split("\n")) {
          const trimmedLine = line.trim();
          if (!trimmedLine || trimmedLine === "data: [DONE]") {
            continue;
          }

          const json = trimmedLine.replace("data: ", "");
          const obj = JSON.parse(json);
          const content =
            obj &&
            obj.choices &&
            obj.choices[0] &&
            obj.choices[0].delta &&
            obj.choices[0].delta.content
              ? obj.choices[0].delta.content.toString()
              : "";
          deltaText = deltaText.concat(content);
        }
        totalString = totalString + deltaText;
        currentSentence = currentSentence + deltaText;

        const newAssistantMessageTimeStamp = new Date();

        const newAssistantMessage = {
          role: "assistant",
          content:
            "CustomMessage:: Alert! This round of interview closes in 1 minute. Try to wrap it up.",
        };

        setConversation([...conversation, newAssistantMessage]);
        setTimeStamps((prevTimeStamps) => [
          ...prevTimeStamps,
          newAssistantMessageTimeStamp,
        ]);
      }
      return totalString;
    } catch (error) {
      console.log("Error:", error);
      return "";
    }
  };
}

export function updateConversationArrays(
  totalString,
  setConversation,
  setTimeStamps,
  latestTimestamps,
  isThisCodeEvaluation
) {
  const newAssistantMessage = {
    role: "assistant",
    content: totalString,
  };
  const newAssistantMessageTimeStamp = new Date();

  if (!isThisCodeEvaluation) {
    setConversation((prevConversation) => {
      const lastMessage =
        prevConversation.length > 0
          ? prevConversation[prevConversation.length - 1]
          : null;
      setTimeStamps([...latestTimestamps, newAssistantMessageTimeStamp]);
      if (lastMessage && lastMessage.role === "assistant") {
        return [...prevConversation.slice(0, -1), newAssistantMessage];
      } else {
        return [...prevConversation, newAssistantMessage];
      }
    });
  }
}
export function getRoundEvaluation({
  conversation,
  userUid,
  userName,
  interviewId,
  inputDataForSystemPrompt,
}) {
  return async (roundTitle) => {
    console.log(
      `DWaraka check evaluaition get${roundTitle.toLowerCase()}RoundEvaluation: `
    );
    let response;

    try {
      // Validate conversation input here if needed
      if (!Array.isArray(conversation)) {
        logAnalyticsErrorEvent({
          eventType: eventTypes.interview_internal_failure,
          eventData: {
            statusCode: 500,
            message: "conversation should be an array",
            api_call_type: `${roundTitle.toLowerCase()}_round_evaluation`,
          },
        });
        return {
          error: {
            message: "Conversation for evaluAItion should be an array.",
            code: 500,
            conversation: conversation,
          },
        };
      }

      let retryCount = 0;

      while (response?.status != 200 && retryCount < 2) {
        response = await performAPIRequest({
          endPoint: `getinterviewroundevaluaition`,
          body: JSON.stringify({
            messages: conversation,
            inputDataForSystemPrompt: encryptData(
              inputDataForSystemPrompt,
              interviewId
            ),
            roundType: roundTitle.toLowerCase(),
            userUid: userUid,
            userName: userName,
            interviewId: interviewId,
            stream: false,
            maxTokens: 1000,
          }),
        });
        retryCount++;
      }
      if (!response || !response.ok) {
        const statusCode = response?.status ? response.status : "Unknown";
        const errorMsg = response?.statusText
          ? response.statusText
          : "No response or bad response(!response.ok)";
        logAnalyticsErrorEvent({
          eventType: eventTypes.gpt_api_failure,
          eventData: {
            statusCode: statusCode,
            endPoint: `get${roundTitle.toLowerCase()}roundevaluaition`,
            api_call_type: `${roundTitle.toLowerCase()}_round_evaluation`,
            errorMsg: errorMsg,
          },
        });
        return {
          error: {
            code: statusCode,
            message: errorMsg,
            api_call_type: `${roundTitle.toLowerCase()}_round_evaluation`,
          },
        };
      }
      const data = await response.json();
      console.log(
        `DWaraka ${roundTitle.toLowerCase()} evaluaition response: ` + data
      );
      return { data: data };
    } catch (error) {
      console.error("Error:", error);
      const statusCode = response?.status ? response.status : "Unknown";
      const errorMsg = response?.statusText
        ? response.statusText
        : error?.message
        ? error.message
        : "Error fetching data from GPT API";
      logAnalyticsErrorEvent({
        eventType: eventTypes.gpt_api_failure,
        eventData: {
          statusCode: statusCode,
          api_call_type: `${roundTitle.toLowerCase()}_round_evaluation`,
          message: errorMsg,
        },
      });
      return {
        error: {
          message: errorMsg,
          endPoint: `get${roundTitle.toLowerCase()}roundevaluaition`,
          code: statusCode,
        },
      };
    }
  };
}

export async function getAlgoRoundEvaluation({
  question,
  userCode,
  conversation,
  userUid,
  interviewId,
}) {
  console.log("DWaraka check evaluaition getAlgoRoundEvaluation: ");
  var response;
  try {
    var i = 0;
    while (!response || (response.status !== 200 && i < 2)) {
      response = await performAPIRequest({
        endPoint: "getalgoroundevaluaition",
        body: JSON.stringify({
          question: question,
          userCode: userCode,
          userUid: userUid,
          interviewId: interviewId,
          messages: conversation,
        }),
      });
      i++;
    }

    if (response.status != 200) {
      logAnalyticsErrorEvent({
        eventType: eventTypes.gpt_api_failure,
        eventData: {
          statusCode: response.status,
          endPoint: "getalgoroundevaluaition",
          api_call_type: "algo_round_evaluation",
        },
      });
      return;
    }

    const data = await response.json();
    console.log("DWaraka algo evaluaition response: " + data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    logAnalyticsErrorEvent({
      eventType: eventTypes.gpt_api_failure,
      eventData: {
        statusCode: response.status,
        endPoint: "getalgoroundevaluaition",
        api_call_type: "algo_round_evaluation",
      },
    });
    return;
  }
}

export function getStoryBasedQuestion({ question }) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      let i = 0;

      while (response?.status !== 200 && i < 2) {
        response = await performAPIRequest({
          endPoint: "getstorybasedhtmldsaquestion",
          body: JSON.stringify({
            question: question,
          }),
        });

        if (response.status !== 200) {
          logAnalyticsErrorEvent({
            eventType: eventTypes.gpt_api_failure,
            eventData: {
              statusCode: response.status,
              endPoint: "getstorybasedhtmldsaquestion",
              api_call_type: "story_question_generation_error",
            },
          });
        }

        i++;
      }

      if (response?.status === 200) {
        const data = await response.json();
        const parsedData = data.choices[0].message.content;
        resolve(parsedData);
      } else {
        reject(
          new Error(`API request failed with status code: ${response?.status}`)
        );
      }
    } catch (error) {
      console.error("Error:", error);
      reject(error);
    }
  });
}

export function getJobRoleBasedQuestion({
  jobRole,
  numberOfQuestions,
  userUId,
}) {
  return async () => {
    let response;
    try {
      let retryCount = 0;

      while (response?.status !== 200 && retryCount < 2) {
        response = await performAPIRequest({
          endPoint: "getrolebasedquestions",
          body: JSON.stringify({
            jobRole: jobRole,
            userUId: userUId,
            numberOfQuestions: numberOfQuestions,
          }),
        });
        if (response?.status === 200) {
          break;
        }
        retryCount++;
      }
      if (!response || !response.ok) {
        const statusCode = response?.status ? response.status : "Unknown";
        const errorMsg = response?.statusText
          ? response.statusText
          : "No response or bad response(!response.ok)";
        logAnalyticsErrorEvent({
          eventType: eventTypes.gpt_api_failure,
          eventData: {
            statusCode: statusCode,
            endPoint: "getJobRoleBasedQuestions",
            api_call_type: "get_job_role_based_questions",
            errorMsg: errorMsg,
          },
        });
        return {
          error: {
            code: statusCode,
            message: errorMsg,
            api_call_type: "get_job_role_based_questions",
          },
        };
      }

      const data = await response.json();

      let decryptedData = decryptData(data, userUId);

      return decryptedData;
    } catch (error) {
      console.error("Error:", error);
      const statusCode = response?.status ? response.status : "Unknown";
      const errorMsg = response?.statusText
        ? response.statusText
        : error?.message
        ? error.message
        : "Error fetching data from GPT API";
      logAnalyticsErrorEvent({
        eventType: eventTypes.gpt_api_failure,
        eventData: {
          statusCode: statusCode,
          api_call_type: "get_job_role_based_questions",
          message: errorMsg,
        },
      });
      return {
        error: {
          message: errorMsg,
          endPoint: "get_job_role_based_questions",
          code: statusCode,
        },
      };
    }
  };
}

export async function getResumeEvaluation({ resumeText, jobDescriptionText }) {
  console.log("DWaraka check getResumeEvaluation: ");
  var response;
  try {
    var i = 0;
    while (!response || (response.status !== 200 && i < 2)) {
      response = await performAPIRequest({
        endPoint: "getresumeevaluaition",
        body: JSON.stringify({
          resumeText: resumeText,
          jobDescriptionText: jobDescriptionText,
        }),
      });
      i++;
    }
    if (response.status != 200) {
      logAnalyticsErrorEvent({
        eventType: eventTypes.gpt_api_failure,
        eventData: {
          statusCode: response.status,
          endPoint: "getresumeevaluaition",
          api_call_type: "resume_evaluaition",
        },
      });
      return;
    }
    const data = await response.json();
    console.log("DWaraka resume evaluaition response: " + data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    logAnalyticsErrorEvent({
      eventType: eventTypes.gpt_api_failure,
      eventData: {
        statusCode: response.status,
        endPoint: "getresumeevaluaition",
        api_call_type: "resume_evaluaition",
      },
    });
    return;
  }
}

export async function getProctoringForImage({ image }) {
  var response;
  try {
    response = await performAPIRequest({
      endPoint: "getvideoproctoring",
      body: JSON.stringify({
        image: image,
      }),
    });
    if (response.status != 200) {
      logAnalyticsErrorEvent({
        eventType: eventTypes.video_proctoring_api_failure,
        eventData: {
          statusCode: response.status,
          endPoint: "getvideoproctoring",
          api_call_type: "video_proctoring",
        },
      });
      return;
    }
    const data = await response.json();
    console.log(
      "DWaraka  video proctoring response: " +
        data.candidates[0].content.parts[0].text
    );
    return data.candidates[0].content.parts[0].text;
  } catch (error) {
    console.error("Error:", error);
    logAnalyticsErrorEvent({
      eventType: eventTypes.video_proctoring_api_failure,
      eventData: {
        statusCode: response.status,
        endPoint: "getvideoproctoring",
        api_call_type: "video_proctoring",
      },
    });
    return;
  }
}
