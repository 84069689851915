import PropTypes from "prop-types";
import React from "react";

const getAlertMessage = (averageScore, totalNumberOfQuestions) => {
  if (totalNumberOfQuestions < 4) {
    return "Scored lower as you were not able to answer the minimum number of questions required";
  } else if (averageScore === 5) {
    return "Scored perfectly by answering all questions! Congratulations on achieving full marks!";
  } else if (averageScore > 3.5) {
    return "Achieved a commendable score by responding well to the questions asked. Great job!";
  } else if (averageScore > 2) {
    return "Attained a moderate score, falling within the average range. Keep up the effort!";
  } else {
    return "Received a lower score, indicating room for improvement. Consider reviewing and practicing to enhance your performance.";
  }
};

const InterviewReportBanner = ({ totalNumberOfQuestions, averageScore }) => {
  InterviewReportBanner.propTypes = {
    totalNumberOfQuestions: PropTypes.number.isRequired,
    averageScore: PropTypes.number.isRequired,
  };
  return (
    <div
      className={`alert my-2 p-2 p-md-3 ${
        totalNumberOfQuestions < 4
          ? "alert-warning"
          : averageScore === 5
          ? "alert-success"
          : averageScore > 3.5
          ? "alert-info"
          : averageScore > 2
          ? "alert-warning"
          : "alert-danger"
      }`}
      role="alert"
    >
      {getAlertMessage(averageScore, totalNumberOfQuestions)}
    </div>
  );
};

export default InterviewReportBanner;
