import PropTypes from "prop-types";
import React from "react";
import Lottie from "react-lottie";
import "./PreloaderSection.css";

const PreloaderSection = ({ animationData, textData, animationWidth }) => {
  PreloaderSection.propTypes = {
    animationData: PropTypes.object.isRequired,
    textData: PropTypes.string,
    animationWidth: PropTypes.string,
  };
  const defaultLoaderOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="preloader-container">
      <div className="text-center">
        <div className="p-events-none">
          <Lottie
            options={defaultLoaderOptions}
            width={animationWidth || "50vw"}
          />
        </div>
        {textData && (
          <h6 className="preloader-text text-base md:text-3xl">{textData}</h6>
        )}
      </div>
    </div>
  );
};

export default PreloaderSection;
