import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import StarRatings from "react-star-ratings";
import "./CodeEvaluationMetricsAccordian.css";

export default function CodeEvaluationMetricsAccordian({ ratingsData }) {
  CodeEvaluationMetricsAccordian.propTypes = {
    ratingsData: PropTypes.object.isRequired,
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const evaluationRatings = ratingsData.evaluation_data.Ratings;

  function camelCaseToTitleCase(input) {
    return input
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/([a-z])([A-Z])$/, "$1 $2")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      });
  }

  return (
    <div>
      <code className="text-center text-dark d-inline-block w-100">
        Code Review
      </code>
      <br />
      <br />
      {evaluationRatings &&
        Object.keys(evaluationRatings).map((ratingKey, index) => {
          if (typeof evaluationRatings[ratingKey] === "object") {
            return (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "40%", flexShrink: 0 }}
                  >
                    {camelCaseToTitleCase(ratingKey)}
                  </Typography>
                  <StarRatings
                    rating={parseFloat(
                      evaluationRatings[ratingKey].rating.split("/")[0]
                    )}
                    starDimension="18px"
                    starRatedColor={getStarColor(
                      evaluationRatings[ratingKey].rating.split("/")[0]
                    )}
                    numberOfStars={5}
                    name={""}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="subtitle2">
                    {evaluationRatings[ratingKey].feedback}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
    </div>
  );
}
function getStarColor(rating) {
  if (rating >= 4.5) {
    return "#4CAF50"; // Green
  } else if (rating >= 3.5) {
    return "#8BC34A"; // Light Green
  } else if (rating >= 2.5) {
    return "#FFC107"; // Yellow
  } else if (rating >= 1.5) {
    return "#FF9800"; // Orange
  } else {
    return "#F44336"; // Red
  }
}
