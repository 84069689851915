import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  findIfThisDataInRealTimeDB,
  getDataFromRealtimeDatabase,
  incrementUserCredits,
} from "../../../Consumer/supportFunctions/FirebaseFunctions";
import { auth } from "../../../firebase";
import { updateVariableATL1CollectionFStore } from "../firebaseFunctions/firestoreFunctions";
import { handleSendMail } from "../sendEmail";
import {
  generateProfileId,
  generateRandomAlphanumeric,
} from "../stringExtensionFunctions";
import {
  addNewUserDataToRealTimeDatabase,
  editCurrentUserTypeInRealTimeDatabase,
  findCurrentUserType,
} from "./AuthFirebaseFunctions";
export async function handleCompleteRegistration({
  setLoading,
  userEmail,
  userUid,
  finalSelectedProfileimageUrl,
  userDisplayName,
  profileId,
  userPhoneNumber,
  dispatch,
  callbackFunc,
}) {
  setLoading(true);
  try {
    const userReferralCode = generateRandomAlphanumeric();
    const signInNotification = {
      title: "Welcome to EvaluAItor",
      description: `You've got ${
        localStorage.getItem("referredById") ? 300 : 200
      } FREE credits to begin your AI mock interviews. Take your first two interviews for FREE!`,
      notificationImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/ImagesForWebsite%2Flogo_s_bg_blue.png?alt=media&token=173283c0-1799-468e-8a17-f7981535745b",
      redirectUrl: `/hiring`,
      timeStamp: new Date(),
    };

    let currentUserType = "normalConsumer";
    let subscribedUnderInstitute = null;
    let subscribedUnderInstituteKey = null;
    let subscribedUnderInstituteLogo = null;
    if (userEmail) {
      const currentUserTypeResult = await findCurrentUserType(
        userEmail,
        userUid
      );
      currentUserType = currentUserTypeResult.subscriptionType;
      subscribedUnderInstituteKey = currentUserTypeResult.instituteKey;
      subscribedUnderInstitute = currentUserTypeResult.instituteName;
      subscribedUnderInstituteLogo = currentUserTypeResult.logo;
    }

    const data = {
      uid: userUid,
      email: userEmail || null,
      profilepic: finalSelectedProfileimageUrl,
      displayName: userDisplayName,
      profileId,
      userRole: null,
      phoneNumber: userPhoneNumber || null,
      credits: localStorage.getItem("referredById") ? 300 : 200,
      linkedinLinked: false,
      showSignInOnboardingModel: true,
      showEarnedCreditsOnboardingModel: false,
      showEarnedJobReferralModel: false,
      isEligibleForJobReferral: false,
      averageInterviewScore: 0,
      totalNumberOfInterviews: 0,
      bestInterviewScore: 0,
      userReferralCode,
      referredById: localStorage.getItem("referredById"),
      referredToUsersList: [],
      lastThreeInterviewScores: [],
      accumulatedTheoryScore: 0,
      accumulatedResumeScore: 0,
      accumulatedDSAScore: 0,
      unSeenNotificationsCount: 1,
      currentUserType,
      subscribedUnderInstitute,
      subscribedUnderInstituteKey,
      subscribedUnderInstituteLogo,
    };

    try {
      await addNewUserDataToRealTimeDatabase(
        data,
        userUid,
        userReferralCode,
        profileId
      );
    } catch (error) {
      console.error(
        "Error adding user data to real-time database:",
        error.message
      );
    }
    if (userEmail) {
      await handleSendMail({
        body: {
          personalization: {
            email: userEmail,
            data: {
              name: userDisplayName,
            },
          },
          templateId: "v69oxl518pd4785k",
          subject: "Welcome to EvaluAItor",
          email: userEmail,
        },
      });
    }
    try {
      await updateVariableATL1CollectionFStore({
        data: signInNotification,
        collectionName: "all-user-notifications-data",
        documentName: userUid,
        variableName: "Notifications",
      });
    } catch (error) {
      console.error(
        "Error updating variable in L1 collection in Firestore:",
        error.message
      );
    }

    const payload = {
      email: userEmail,
      uid: userUid,
      profilepic: finalSelectedProfileimageUrl,
      displayName: userDisplayName,
      profileId,
      userReferralCode,
      phoneNumber: userPhoneNumber,
      unSeenNotificationsCount: data.unSeenNotificationsCount || 0,
    };

    if (currentUserType === "normalConsumer") {
      payload.credits = localStorage.getItem("referredById") ? 300 : 200;
    } else {
      payload.currentUserType = currentUserType;
      payload.subscribedUnderInstitute = subscribedUnderInstitute;
      payload.subscribedUnderInstituteKey = subscribedUnderInstituteKey;
      payload.subscribedUnderInstituteLogo = subscribedUnderInstituteLogo;
    }

    dispatch({
      type: "ADD_USER_DATA",
      payload,
    });

    if (localStorage.getItem("referredById")) {
      try {
        const referredByUserId = await getDataFromRealtimeDatabase(
          `/AllUserReferralList/${localStorage.getItem("referredById")}`
        );
        if (referredByUserId !== userUid) {
          await incrementUserCredits(
            "userSpecifReferralArrayUpdate",
            "referredToUsersList",
            userUid,
            referredByUserId
          );
        }
      } catch (error) {
        console.error("Error incrementing user credits:", error.message);
      }
    }

    window.localStorage.removeItem("referredById");
    setLoading(false);
    callbackFunc();
  } catch (error) {
    setLoading(false);
    console.error("Error completing registration:", error.message);
  }
}

export async function handleGoogleSignIn({ setLoading, callbackFunc }) {
  setLoading(true);
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const userProfileId = generateProfileId(user.displayName);
    var userTypePreviousValue = await findIfThisDataInRealTimeDB(
      user.uid,
      "AllUserUUIDAndCurrentUserTypeList"
    );
    console.log("Dwaraka findIfOldUser: ", userTypePreviousValue);
    console.log("Dwaraka profileId: ", userProfileId);
    if (!userTypePreviousValue) {
      await handleCompleteRegistration({
        setLoading,
        userEmail: user.email,
        userUid: user.uid,
        finalSelectedProfileimageUrl: user.photoURL,
        userDisplayName: user.displayName,
        profileId: userProfileId,
        userPhoneNumber: null,
        callbackFunc: callbackFunc,
      });
      callbackFunc({ userEmail: user.email, userUid: user.uid });
    } else {
      const currentUserTypeResult = await findCurrentUserType(
        user.email,
        user.uid
      );
      const currentUserType = currentUserTypeResult.subscriptionType;
      if (currentUserType != userTypePreviousValue) {
        console.log("Dwaraka not same");
        await editCurrentUserTypeInRealTimeDatabase(
          currentUserType,
          user.uid,
          "AllUserUUIDAndCurrentUserTypeList",
          user.uid
        );
        await editCurrentUserTypeInRealTimeDatabase(
          currentUserTypeResult.instituteKey,
          "subscribedUnderInstitute",
          "AllUsersData",
          user.uid
        );
        await editCurrentUserTypeInRealTimeDatabase(
          currentUserTypeResult.logo,
          "subscribedUnderInstituteLogo",
          "AllUsersData",
          user.uid
        );
        await editCurrentUserTypeInRealTimeDatabase(
          currentUserType,
          "currentUserType",
          "AllUsersData",
          user.uid
        );
      }
      console.log("Dwaraka Siri login successful");
      callbackFunc({ userEmail: user.email, userUid: user.uid });
    }
    setLoading(false);
  } catch (error) {
    console.log(error.message);
  }
}
