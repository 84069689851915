export function getSiteBusinessName() {
  // Get the hostname from the current URL
  const hostname = window.location.hostname;

  // Split the hostname by '.'
  const parts = hostname.split(".");

  // Extract the first part
  var businessName = parts[0];
  if (businessName === "localhost") {
    businessName = "business-admin";
  }
  return businessName;
}
