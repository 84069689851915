import Editor from "@monaco-editor/react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React from "react";
import ModalCloseButton from "../../../../Global/components/Buttons/ModalCloseButton/ModalCloseButton";
import { reportDetailModalStyle } from "./ReportModalConstants";
import "./reportDetailsModal.css";
export default function CodingRoundReportDetailModal(
  shouldOpenNonCodingRoundReviewModel,
  setShouldOpenNonCodingRoundReviewModel,
  indexForModel,
  dataSourceForModel
) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldOpenNonCodingRoundReviewModel}
      onClose={() => {
        setShouldOpenNonCodingRoundReviewModel(false);
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldOpenNonCodingRoundReviewModel}>
        <Box sx={reportDetailModalStyle}>
          <div className="modal-content onlineTestReportModalContent h-100">
            <div
              className={`modal-header m-auto ${
                shouldOpenNonCodingRoundReviewModel
                  ? "reportModalFixedHeader p-2 p-md-4"
                  : ""
              }`}
            >
              <span
                className="feedback_modal_heading text-xs md:text-base"
                style={{
                  color: indexForModel % 2 == 0 ? "#5e6d9e" : "#ae866d",
                }}
              >
                <h4 className="text-center text-primary">
                  Submission details:
                </h4>
              </span>
              <ModalCloseButton
                aria-label="CloseModal"
                onModalClose={() =>
                  setShouldOpenNonCodingRoundReviewModel(false)
                }
              />
            </div>
            <div className="modal-body reportModalBody h-100">
              <span className="css-12tgxhh-QuestionLink e5i1odf11 d-block h-100 text-xs md:text-base">
                <div className="row h-100">
                  <div className="col-lg-6">
                    {dataSourceForModel.question}
                    <p className="my-2">
                      <strong>Sample input:</strong>{" "}
                      {dataSourceForModel.sampleInput}
                    </p>
                    <p className="my-2">
                      <strong>Sample output:</strong>{" "}
                      {dataSourceForModel.sampleOutput}
                    </p>
                    <p className="my-2">
                      <strong>Language:</strong>{" "}
                      {dataSourceForModel.selectedLanguage}
                    </p>
                    <p className="my-2">
                      <strong>Number of test cases passed:</strong>{" "}
                      {dataSourceForModel.bestScore} / 10
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <Editor
                      value={dataSourceForModel.bestScoreCodeInput}
                      defaultLanguage="cpp"
                      height="100%"
                      theme="vs-dark"
                      options={{
                        readOnly: true, // Set this to true to disable editing
                      }}
                      defaultValue={dataSourceForModel.bestScoreCodeInput}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </span>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
