import { lazy } from "react";
import LoginPage from "./Pages/Auth/LoginPage";
import LandingPage from "./Pages/LandingPage/LandingPage";
import TestResultsPage from "./Pages/TestResultsPage/TestResultsPage";
const ForgotPasswordPage = lazy(() =>
  import("./Pages/Auth/ForgotPasswordPage")
);
const RegisterPage = lazy(() => import("./Pages/Auth/RegisterPage"));

const businessAdminRoutes = [
  {
    path: "/login",
    component: LoginPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/register",
    component: RegisterPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/forgot/password",
    component: ForgotPasswordPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/",
    component: LandingPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/evaluaitions/:testslug",
    component: TestResultsPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
];
export default businessAdminRoutes;
