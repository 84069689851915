import { lazy } from "react";

const InterviewRetake = lazy(() =>
  import("./pages/InterviewRetake/InterviewRetake")
);

const globalRoutes = [
  {
    path: "/interview-retake/:parent_collectionSlug/:child_collectionSlug/:interviewIDSlug",
    component: InterviewRetake,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
];
export default globalRoutes;
