export function extractTextFromPdf(pdfFile) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const fileReader = new FileReader();

      // Define a promise-based function to read the file
      const readPdfFile = (file) => {
        return new Promise((resolve, reject) => {
          fileReader.onload = (event) => {
            const arrayBuffer = event.target.result;
            resolve(arrayBuffer);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
          fileReader.readAsArrayBuffer(file);
        });
      };

      // Read the PDF file
      const arrayBuffer = await readPdfFile(pdfFile);

      // Initialize PDF.js
      const pdfjs = await import("pdfjs-dist/build/pdf");
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

      // Load the PDF and extract text
      const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
      let fullText = "";

      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map((item) => item.str).join(" ");
        fullText += pageText + "\n";
      }

      // Resolve the Promise with the extracted text
      resolve({ text: fullText, numPages: pdf.numPages });
    } catch (error) {
      // Reject the Promise if an error occurs
      reject(error);
    }
  });
}

export function handleMediaPlayError(error, mediaType) {
  if (error.code === DOMException.MEDIA_ERR_SRC_NOT_SUPPORTED) {
    console.error("Media source not supported.");
    // Handle the error gracefully, maybe log it or show a message to the user.
  } else {
    console.error(
      `Error occurred while playing ${mediaType}: ${error.message}`
    );
    // Handle other errors as needed.
  }
}

// Helper function to shuffle an array
export function shuffleArray(array) {
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}
