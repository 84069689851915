import PropTypes from "prop-types";
import React from "react";
import "./InterviewConvoDataCard.css";
import ReportsMessageBubble from "./ReportsMessageBubble/ReportsMessageBubble";
export default function InterviewConvoDataCard({ data }) {
  InterviewConvoDataCard.propTypes = {
    data: PropTypes.array.isRequired,
  };
  return (
    <div>
      {data
        ?.sort((a, b) => (a.key > b.key ? 1 : -1))
        .map((item, index) => (
          <div key={index} className="polymorphism-card card py-1 py-md-3 my-2">
            <div key={index} className="polymorphism-card my-2">
              <h5 className="text-primary border-bottom ps-2">
                {item?.topic || ""}
              </h5>
              {item.question && (
                <ReportsMessageBubble
                  message={
                    <>
                      <strong>Question:</strong> {item.question}
                    </>
                  }
                  sender={"question"}
                  badgeText={`Topic : ${item.topic}`}
                />
              )}
              <ReportsMessageBubble
                message={
                  <>
                    <strong>Candidate answer:</strong>{" "}
                    {item.candidate_answer_summary}
                  </>
                }
                sender={"user_answer"}
                badgeText="Your answer"
              />
              <ReportsMessageBubble
                message={
                  <>
                    <strong>Suggested answer:</strong> {item.suggested_answer}
                  </>
                }
                sender={"bot_answer"}
                badgeText="Suggested answer"
              />
              <ReportsMessageBubble
                message={
                  <>
                    <strong>Reasoning:</strong> {item.reasoning}
                  </>
                }
                sender={"rating"}
                badgeText={`Rating: ${item.rating}/5`}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
