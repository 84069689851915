import { Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";
import React from "react";
import ModalCloseButton from "../../../../Global/components/Buttons/ModalCloseButton/ModalCloseButton";
import "./RoundReportModal.css";

export default function RoundReportModal({
  shouldShowReportModal,
  setShouldShowReportModal,
  children,
  title,
}) {
  const roundReportModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "90vh",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldShowReportModal}
      onClose={() => {
        setShouldShowReportModal(false);
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldShowReportModal}>
        <Box sx={roundReportModalStyle}>
          <div className="modal-content report-form-main">
            <div className="modal-header m-auto reportModalFixedHeader justify-content-between">
              <p className="report-form-heading modal-header mb-0 mx-2">
                {title}
              </p>
              <ModalCloseButton
                onModalClose={() => setShouldShowReportModal(false)}
              />
            </div>
            <div className="modal-body reportModalBody">{children}</div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
RoundReportModal.propTypes = {
  shouldShowReportModal: PropTypes.bool.isRequired,
  setShouldShowReportModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};
