import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const loadingMessage = {
  role: "assistant",
  content: (
    <>
      <FontAwesomeIcon icon={faSpinner} spin spinReverse />
      <FontAwesomeIcon
        icon={faSpinner}
        style={{ margin: "0 10px" }}
        spinPulse
      />
      <FontAwesomeIcon icon={faSpinner} spin spinReverse />
      <FontAwesomeIcon
        icon={faSpinner}
        style={{ margin: "0 10px" }}
        spinPulse
      />
      <FontAwesomeIcon icon={faSpinner} spin spinReverse />
    </>
  ),
};

export const interviewStates = {
  interviewNotStarted: "interviewNotStarted",
  interviewInProgress: "interviewInProgress",
  interviewSettingUp: "interviewSettingUp",
  interviewSettingDone: "interviewSettingDone",
  dipAlreadySolved: "dipAlreadySolved",
  dipTimerOut: "dipTimerOut",
  interviewPaused: "interviewPaused",
  interviewEnded: "interviewEnded",
  interviewFailed: "interviewFailed",
};

export const roundStatus = {
  Unattempted: "Unattempted",
  Incomplete: "Incomplete",
  Completed: "Completed",
};

export const avatarVoiceMappings = {
  Arjun: {
    languageCode: "en-In",
    ssmlGender: "MALE",
  },
  Shreya: {
    languageCode: "en-In",
    ssmlGender: "FEMALE",
  },
  Mark: {
    languageCode: "en-US",
    ssmlGender: "MALE",
  },
  Callie: {
    languageCode: "en-US",
    ssmlGender: "FEMALE",
  },
};
