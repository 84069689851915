import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import CompletionCertificate from "../../Assets/Images/CompletionCertificate.png";
import "./CertificateOfCompletion.css";
const CertificateOfCompletion = React.forwardRef(
  ({ name, score, date, role, setShowCertificate }, ref) => {
    CertificateOfCompletion.propTypes = {
      name: PropTypes.string,
      score: PropTypes.number,
      date: PropTypes.string,
      role: PropTypes.string,
      setShowCertificate: PropTypes.func,
    };
    CertificateOfCompletion.displayName = "CertificateOfCompletion";
    const { enqueueSnackbar } = useSnackbar();
    function handlePdfDownlod() {
      htmlToImage
        .toPng(document.getElementById("completionCertificate"), {
          quality: 1.0,
        })
        .then(function (dataUrl) {
          const pdf = new jsPDF("landscape");
          const imgProps = pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(`${name}_EvaluAItor.pdf`);
          setShowCertificate(false);
          enqueueSnackbar("Certificate downloaded successfully", {
            variant: "success", // You can customize the variant
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        });
    }
    React.useImperativeHandle(ref, () => ({
      handlePdfDownlod,
    }));

    return (
      <div className="completionCertificate" id="completionCertificate">
        <div>
          <div
            className="magazineMaindiv"
            style={{
              backgroundImage: "url(" + CompletionCertificate + ")",
            }}
          >
            <div className="container justify-content-center">
              <div>
                <h1 className="CandidateName">{name}</h1>
                <p className="description">
                  This is to certify as a sign that you have completed an
                  Interview with AI in EvaluAItor for <strong>{role}</strong>{" "}
                  role and achieved <strong>{score}</strong>/10 on {date}. All
                  the best for the next AI interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CertificateOfCompletion;
