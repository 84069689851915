import { encode } from "gpt-tokenizer";

export function countTokens({ inputString }) {
  const tokens = encode(inputString);
  return tokens.length;
}

export const copyTextToClipboard = ({
  textToCopy,
  enqueueSnackbar,
  toastText,
}) => {
  // Create a temporary input element
  const tempInput = document.createElement("input");
  tempInput.value = textToCopy;
  // Append the input element to the DOM
  document.body.appendChild(tempInput);
  // Select the input content
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices
  // Copy the selected text to the clipboard
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  enqueueSnackbar(`${toastText} copied.`, {
    variant: "success",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  });
};

export function generateRandomAlphanumeric() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export function generateProfileId(input) {
  // Remove ".", "#", "$", "[", or "]" from the input
  input = input.replace(/[.#$[\]]/g, "");
  console.log("DWaraka generateProfileId input: ", input);
  const words = input.split(" ");
  let username = "";

  if (words.length >= 1) {
    username += words[0].toLowerCase();

    if (words.length >= 2) {
      username += `-${words[1].toLowerCase()}`;
    }
  }

  const randomNumber = Math.floor(Math.random() * 100);

  if (username === "") {
    // If there's only one word, add a 3-digit random number
    username = `user-${randomNumber.toString().padStart(3, "0")}`;
  } else if (words.length == 1) {
    username += randomNumber.toString().padStart(3, "0");
  } else {
    // If there are two words, add a 2-digit random number
    username += randomNumber.toString().padStart(2, "0");
  }

  return username;
}

export function isAlphaNumericWithHyphen(str) {
  // Define a regular expression pattern that matches alphanumeric characters and hyphens
  const pattern = /^[a-zA-Z0-9-]+$/;

  // Test if the string matches the pattern
  return pattern.test(str);
}

export const convertToTitleCase = (str) => {
  let words = [];
  // Split the string into words using underscores, hyphens, or camel case as separators
  words = str?.split(/[_-]|(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/);

  let titleCaseString = "";

  if (words?.length > 0) {
    // Capitalize the first letter of each word and join them back with a space
    titleCaseString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return titleCaseString;
};
export const convertToTitleCaseInReverse = (str) => {
  // Split the string into words using underscores, hyphens, or camel case as separators
  const words = str.split(/[_-]|(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/);

  // Capitalize the first letter of each word and join them back with a space
  const titleCaseString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .reverse()
    .join(" ");

  return titleCaseString;
};

// utility function to clean fraud email addresses dwaraka.bits+1d34@gmail.com to dwaraka.bits@gmail.com
export function cleanEmailAddress(email) {
  return email.replace(/\+.*?(?=@)/g, "");
}

export function capitalizeFirstLetter(str) {
  if (!str) return "";
  let lowStr = str.toLowerCase();
  let capStr = lowStr.charAt(0).toUpperCase() + lowStr.slice(1);
  return capStr;
}

export function parseValidJSONFromString(str) {
  // Find the index of the first '{' and the last '}'
  const startIndex = str.indexOf("{");
  const endIndex = str.lastIndexOf("}");

  // Extract the JSON substring
  const jsonSubstring = str.substring(startIndex, endIndex + 1);

  // Parse the JSON string and return the object
  return JSON.parse(jsonSubstring);
}

export function formatTimeToString(seconds) {
  seconds = Number(seconds);
  if (isNaN(seconds) || seconds < 0) {
    return "NA";
  }

  var hours = Math.floor(seconds / 3600);
  var remainingSeconds = seconds % 3600;
  var minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = Number(remainingSeconds % 60).toFixed(0);

  var result = "";

  if (hours > 0) {
    result += hours + (hours === 1 ? " hour" : " hours");
  }

  if (minutes > 0) {
    if (result !== "") {
      result += ", ";
    }
    result += minutes + (minutes === 1 ? " min" : " mins");
  }

  if (seconds >= 0 && result === "") {
    result +=
      remainingSeconds + (remainingSeconds === 1 ? " second" : " seconds");
  } else if (seconds > 0 && result !== "" && remainingSeconds > 0) {
    result += ", ";
    result +=
      remainingSeconds + (remainingSeconds === 1 ? " second" : " seconds");
  }
  return result;
}

export const getCompanyDomainTitle = (str) => {
  if (!str) return "";
  console.log("Dwaraka Siri getCompanyDomainTitle: ", str);
  // Split the string by space
  const words = str?.split(" ");
  // Get the first word
  let firstWord = words[0];
  // If the first word is less than 5 characters and there are more words
  if (firstWord.length < 5 && words.length > 1) {
    // Take characters until the next space
    for (let i = 1; i < words.length; i++) {
      const nextWord = words[i];
      firstWord += nextWord;
    }
  }

  return firstWord;
};

export const formatSeconds = (num) => {
  return num < 10 ? `0${num}` : num;
};

export function getRandomOrdinalString({ min, max }) {
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  const getOrdinalString = (number) => {
    const suffixes = [
      "th",
      "st",
      "nd",
      "rd",
      "th",
      "th",
      "th",
      "th",
      "th",
      "th",
    ];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const getRespectiveString = (number) => {
    const strings = [
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
    ];
    return strings[number - 1];
  };

  const randomString = getRespectiveString(randomNumber);

  return {
    randomNumber,
    ordinalString: getOrdinalString(randomNumber),
    respectiveString: randomString,
  };
}
