import { getAnalytics, logEvent } from "@firebase/analytics";
import { getAuth } from "firebase/auth";

// eslint-disable-next-line no-unused-vars
export const logout = async ({ dispatch, navigate, location }) => {
  const auth = getAuth();
  logAnalyticsClickEvent("logout");
  try {
    await auth.signOut();
    dispatch({
      type: "LOGIN_REDIRECT",
      payload: location.pathname,
    });

    dispatch({
      type: "LOGOUT",
      payload: null,
    });

    dispatch({
      type: "RESET_USER_STATE",
    });
    // navigate("/login");
  } catch (error) {
    console.error("Logout error:", error);
  }
};

export const logAnalyticsClickEvent = (name) => {
  const analytics = getAnalytics();
  logEvent(analytics, "click-event", {
    content_placement: "navbar",
    content_type: "link",
    content_name: name,
  });
};
