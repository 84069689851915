import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import ModalCloseButton from "../../../../Global/components/Buttons/ModalCloseButton/ModalCloseButton";
import "./AlertModal.css";

const AlertModal = ({
  onReject,
  show,
  onPrimaryBtnClick,
  onDangerBtnClick,
  text,
  heading,
  dangerBtnText,
  primaryBtnText,
}) => {
  AlertModal.propTypes = {
    show: PropTypes.bool,
    onReject: PropTypes.func,
    onPrimaryBtnClick: PropTypes.func,
    onDangerBtnClick: PropTypes.func,
    text: PropTypes.string,
    heading: PropTypes.string,
    dangerBtnText: PropTypes.string,
    primaryBtnText: PropTypes.string,
  };
  return (
    <Modal centered show={show} onHide={onReject}>
      <div className="d-flex justify-content-between align-items-center modal-header py-2 form-heading">
        <p className="mb-0">{heading}</p>
        <ModalCloseButton onModalClose={onReject} />
      </div>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer className="justify-content-evenly border-0">
        {primaryBtnText && (
          <button
            aria-label="Cancel"
            className="btn btn-outline-primary"
            onClick={onPrimaryBtnClick}
          >
            {primaryBtnText}
          </button>
        )}
        {dangerBtnText && (
          <button
            aria-label="Exit"
            className="btn btn-outline-danger"
            onClick={onDangerBtnClick}
          >
            {dangerBtnText}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
