import {
  faArrowRight,
  faBuilding,
  faCircleInfo,
  faFileLines,
  faUserTie,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Zoom } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import RemainingTimeCounter from "../../../Business/components/RemainingTimeCounter/RemainingTimeCounter";
import BusinessNavBar from "../../../Business/layouts/BusinessNavBar/BusinessNavBar";
import { fetchAllBusinessPageData } from "../../../Business/utils/FirebaseFunctions/realtimeDBFunctions";
import { logout } from "../../../Business/utils/authFunctions";
import { removePrivateAccessVaraible } from "../../../Business/utils/stringExtensionFunction";
import interviewHRFemale from "../../../Global/assets/Images/AllRoundsImages/interveiwHRMale.webp";
import interviewHRFemalePlaceholder from "../../../Global/assets/Images/AllRoundsImages/interviewHRFemalePlaceholder.webp";
import interviewTechnical from "../../../Global/assets/Images/AllRoundsImages/interviewTechnical.webp";
import interviewTechnicalPlaceholder from "../../../Global/assets/Images/AllRoundsImages/interviewTechnicalPlaceholder.webp";
import objectiveTest from "../../../Global/assets/Images/AllRoundsImages/objectiveTest.webp";
import objectiveTestPlaceholder from "../../../Global/assets/Images/AllRoundsImages/objectiveTestPlaceholder.webp";
import onlineCoding from "../../../Global/assets/Images/AllRoundsImages/onlineCoding.webp";
import onlineCodingPlaceholder from "../../../Global/assets/Images/AllRoundsImages/onlineCodingPlaceholder.webp";
import homePagePreloaderAnimation from "../../../Global/assets/LottieFiles/homePagePreloaderAnimation.json";
import PageHelmet from "../../../Global/components/PageHelmet/PageHelmet";
import PreloaderSection from "../../../Global/components/PreloaderSection/PreloaderSection";
import ProgressiveImage from "../../../Global/components/ProgressiveImage/ProgressiveImage";
import useFullscreen from "../../../Global/customHooks/useFullscreen";
import {
  capitalizeFirstLetter,
  convertToTitleCase,
} from "../../../Global/utils/stringExtensionFunctions";
import {
  addNewAdminDataForCompany,
  findIfValidBusinessUser,
  getAllBusinessCandidatesList,
} from "../../utils/FirebaseFunctions/realtimeDBFunctions";
import { getSiteBusinessName } from "../../utils/stringExtensionFunction";
import "./LandingPage.css";

const LandingPage = () => {
  const { businessSlug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdminUpdateLoading, setIsAdminUpdateLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({ ...state }));
  const { isFullscreen } = useFullscreen();
  // eslint-disable-next-line no-unused-vars
  const [businessName, setBusinessName] = useState("");
  const [allTestsData, setAllTestsData] = useState({});
  const [totalTestTakers, setTotalTestTakers] = useState(0);
  const [newCandEmail, setNewCandEmail] = useState("");
  const [isNewCandEmailValid, setIsNewCandEmailValid] = useState(true);

  useEffect(() => {
    const getPageData = async ({ pageName }) => {
      setIsLoading(true);
      const data = await fetchAllBusinessPageData({
        realTimeDBPath: `${capitalizeFirstLetter(
          businessSlug ?? getSiteBusinessName().split("-")[0]
        )}_all_pages_data`,
        categoryName: pageName,
      });
      console.log(`DWaraka Siri ${pageName} data: `, data);
      return data;
    };

    const getTestsList = async (data) => {
      const promises = Object.keys(data).map(async (key) => {
        if (
          key !== "valid_users_mails" &&
          Array.isArray(removePrivateAccessVaraible(data[key]))
        ) {
          const testPageData = await getPageData({ pageName: key });
          return { [key]: testPageData };
        }
        return null; // Return null for keys that are not processed
      });
      const results = await Promise.all(promises);
      const mergedResults = results.reduce((acc, curr) => {
        if (curr !== null) {
          return { ...acc, ...curr };
        }
        return acc;
      }, {});
      return mergedResults;
    };

    const isValidAdmin = async ({ user, busName }) => {
      if (!businessSlug) {
        const isThisUserAdmin = await findIfValidBusinessUser(
          user.email,
          `${capitalizeFirstLetter(
            businessSlug ?? getSiteBusinessName()
          )}_candidates_list`
        );
        if (!isThisUserAdmin) {
          logout({ dispatch, navigate, location });
          navigate("/login");
          return;
        }
      }
      const allCandidatesObj = await getAllBusinessCandidatesList(
        `${busName.split("-")[0]}_candidates_list`
      );
      Object.keys(allCandidatesObj).map(async (key) => {
        if (key !== "valid_users_mails") {
          setTotalTestTakers(
            (prev) =>
              prev + removePrivateAccessVaraible(allCandidatesObj[key]).length
          );
        }
      });

      const list = await getTestsList(allCandidatesObj);
      setAllTestsData(list);
      setIsLoading(false);

      console.log("DWaraka Siri all tests list: ", list);
    };

    const initializeData = async () => {
      let busName = capitalizeFirstLetter(
        businessSlug ?? getSiteBusinessName()
      );
      setBusinessName(busName);
      if (user?.uid) {
        const landingData = await getPageData({ pageName: "landing_page" });
        setPageData(landingData);
        await isValidAdmin({ user: user, busName: busName });
      }
    };

    initializeData();
  }, [user]);

  const addNewAdmin = async (e) => {
    e.preventDefault();
    setIsAdminUpdateLoading(true);
    let realTimeDBPath = `${capitalizeFirstLetter(
      businessSlug ?? getSiteBusinessName().split("-")[0]
    )}-admin_candidates_list`;
    let data = {
      email: newCandEmail,
      uid: "",
    };
    await addNewAdminDataForCompany({
      realTimeDBPath,
      newCandidate: data,
    });
    setNewCandEmail("");
    setIsAdminUpdateLoading(false);
  };

  return (
    <div className="landing-page">
      <main className="landingPage">
        <PageHelmet
          title={"Business: Landing Page"}
          description={
            "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
          }
        />
        {!isFullscreen && !isLoading && (
          <BusinessNavBar
            sticky={true}
            useAnchorTag={false}
            restrictRedirection={false}
            landingPageData={pageData}
          />
        )}
        {isLoading ? (
          <PreloaderSection
            animationData={homePagePreloaderAnimation}
            textData={"Awakening your AI interview partner..."}
          />
        ) : (
          <div className="all-tests-list-page">
            <section className="px-3 py-5 px-md-5 bg-blue-gray-50 border-bottom border-blue-gray-100 graphs-container container-fluid">
              {/* <h4 className="text-center mb-4">ADMIN: {pageData.title}</h4> */}
              {/* <p className="text-center">{pageData.companyDescription}</p> */}
              <div className="max-width-1440 w-100">
                <div className="row">
                  <div className="mb-3 testTakerSection col-xl-3 col-lg-4 px-0 pe-md-3">
                    <div className="d-flex flex-column h-100 justify-content-start h-100">
                      <div className="bg-white border border-blue-gray-300 mb-3 rounded">
                        <div className="d-flex justify-content-between align-items-center border-bottom text-blue-gray-700 border-blue-gray-300 p-3 bg-white">
                          <span className="ft-16 font-weight-500">
                            <FontAwesomeIcon
                              className="me-2"
                              icon={faBuilding}
                            />
                            Company Overview
                          </span>
                          <Tooltip
                            title="This section displays the basic details of the company."
                            placement="top"
                            arrow
                            className="password-tooltip"
                            TransitionComponent={Zoom}
                          >
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              className="px-2 ms-auto pointer-cursor password-tooltip"
                            />
                          </Tooltip>
                        </div>
                        <div>
                          <div className="p-3">
                            <div className="d-flex align-items-center ">
                              <img
                                loading="lazy"
                                className=" mb-2 px-0 mx-1 business-logo-image rounded"
                                src={pageData.logo}
                                alt="user-dafault-avatar1"
                              />
                              <div className="d-flex justify-content-between flex-column">
                                <p className="mb-1">{pageData.companyName}</p>
                                <small className="text-blue-gray-700">
                                  {pageData.companyEmail}
                                </small>
                              </div>
                            </div>
                            {pageData.companyDescription}
                          </div>
                        </div>
                      </div>
                      <div className="bg-white mb-3 border-css rounded-1 p-1px">
                        <div className="d-flex justify-content-between align-items-center border-bottom text-blue-gray-700 border-blue-gray-300 p-3 bg-white">
                          <span className="ft-16 fw-500">
                            <FontAwesomeIcon
                              className="me-3"
                              icon={faFileLines}
                            />
                            Total Tests
                          </span>
                          <Tooltip
                            title="This section displays the total count of tests created, inprogress and completed in 1 year."
                            placement="top"
                            arrow
                            className="password-tooltip"
                            TransitionComponent={Zoom}
                          >
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              className="px-2 ms-auto pointer-cursor password-tooltip"
                            />
                          </Tooltip>
                        </div>
                        <div className="cursor-pointer px-2">
                          <div className="d-flex justify-content-center align-items-center py-4">
                            <img
                              className="px-2 w-65"
                              alt=""
                              src="https://mettl.com/account/images/test_takers.svg"
                            />
                            <span className="fs-2 font-weight-500 text-blue-700 totalTestCount">
                              {Object.keys(allTestsData).length}
                            </span>
                          </div>
                          {businessSlug && (
                            <Link
                              disabled={isLoading || isAdminUpdateLoading}
                              to={`/admin/business-test-onboarding?bName=${
                                pageData.companyName
                              }&bEmail=${
                                pageData.companyEmail
                              }&bLogo=${encodeURIComponent(
                                pageData.logo.replace(/%2F/g, "%252F")
                              )}`}
                              type="submit"
                              defaultValue="Log In"
                              className="btn w-100 btn-primary mb-2"
                            >
                              Add new Test
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="bg-white mb-3 border border-blue-gray-300 rounded">
                        <div className="d-flex justify-content-between align-items-center border-bottom text-blue-gray-700 border-blue-gray-300 p-3 bg-white">
                          <span className="ft-16 font-weight-500">
                            <FontAwesomeIcon className="me-2" icon={faUsers} />
                            Total Tests-takers
                          </span>
                          <Tooltip
                            title="This section displays the count of all test takers for all the tests in 1 year."
                            placement="top"
                            arrow
                            className="password-tooltip"
                            TransitionComponent={Zoom}
                          >
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              className="px-2 ms-auto pointer-cursor password-tooltip"
                            />
                          </Tooltip>
                        </div>
                        <div>
                          <div className="d-flex justify-content-center align-items-center p-3">
                            <img
                              className="w-65 px-2"
                              alt=""
                              src="https://mettl.com/account/images/total_test_taker.svg"
                            />
                            <span className="fs-2 font-weight-500 text-blue-700 totalTestCount">
                              {totalTestTakers}
                            </span>
                          </div>
                        </div>
                      </div>
                      {businessSlug && (
                        <div className="bg-white border border-blue-gray-300 rounded">
                          <div className="d-flex justify-content-between align-items-center border-bottom text-blue-gray-700 border-blue-gray-300 p-3 bg-white">
                            <span className="ft-16 font-weight-500">
                              <FontAwesomeIcon
                                className="me-2"
                                icon={faUserTie}
                              />
                              Add new admins
                            </span>
                            <Tooltip
                              title="You can add new admins from here."
                              placement="top"
                              arrow
                              className="password-tooltip"
                              TransitionComponent={Zoom}
                            >
                              <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="px-2 ms-auto pointer-cursor password-tooltip"
                              />
                            </Tooltip>
                          </div>
                          <div>
                            <div className="d-flex justify-content-center align-items-center p-3">
                              <form onSubmit={addNewAdmin} className="w-100">
                                <div className="form-group first">
                                  {!isNewCandEmailValid && (
                                    <small className="text-danger">
                                      (please enter a valid email)
                                    </small>
                                  )}
                                  <input
                                    onChange={(e) => {
                                      const emailRegex =
                                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                      if (!emailRegex.test(e.target.value)) {
                                        setIsNewCandEmailValid(false);
                                      } else {
                                        setIsNewCandEmailValid(true);
                                      }
                                      setNewCandEmail(e.target.value);
                                    }}
                                    value={newCandEmail}
                                    required
                                    type="email"
                                    className="form-control my-2"
                                    id="email-address"
                                    placeholder="your-email@gmail.com"
                                  />
                                </div>
                                <button
                                  disabled={
                                    !isNewCandEmailValid ||
                                    !newCandEmail ||
                                    isAdminUpdateLoading
                                  }
                                  onClick={(e) => addNewAdmin(e)}
                                  type="submit"
                                  defaultValue="Log In"
                                  className="btn w-100 btn-primary mt-1"
                                >
                                  Add Admin
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-3 recentTestSection col-xl-9 col-lg-8 px-0">
                    <div className="bg-white h-100 border border-blue-gray-300 rounded">
                      <div className="d-flex justify-content-between align-items-center border-bottom text-blue-gray-700 border-blue-gray-300 p-3 bg-white">
                        <span className="ft-16 font-weight-500">
                          <FontAwesomeIcon
                            className="me-3"
                            icon={faFileLines}
                          />
                          Recent Tests
                        </span>
                        <Tooltip
                          title="This section shows the tests which are inprogress or tests where test-takers have recently completed the test."
                          placement="top"
                          arrow
                          className="password-tooltip"
                          TransitionComponent={Zoom}
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="px-2 ms-auto pointer-cursor password-tooltip"
                          />
                        </Tooltip>
                      </div>
                      <div className="recentTestSectionBlock bg-white p-3">
                        <div className="m-0 p-0 row">
                          {Object.keys(allTestsData).map((key, index) => (
                            <div
                              key={index}
                              className="mb-3 col-xxl-4 col-lg-6 col-md-12 px-0 px-md-1 px-xl-2"
                            >
                              <div className="rounded cursor-pointer px-2 py-3 bg-blue-gray-50">
                                <ProgressiveImage
                                  src={
                                    Object.keys(
                                      allTestsData[key].roundsData
                                    )[0].startsWith("onlineTest")
                                      ? objectiveTest
                                      : Object.keys(
                                          allTestsData[key].roundsData
                                        )[0] === "codingRound"
                                      ? onlineCoding
                                      : Object.keys(
                                          allTestsData[key].roundsData
                                        )[0] === "interview-Technical"
                                      ? interviewHRFemale
                                      : interviewTechnical
                                  }
                                  placeholderSrc={
                                    Object.keys(
                                      allTestsData[key].roundsData
                                    )[0].startsWith("onlineTest")
                                      ? objectiveTestPlaceholder
                                      : Object.keys(
                                          allTestsData[key].roundsData
                                        )[0] === "codingRound"
                                      ? onlineCodingPlaceholder
                                      : Object.keys(
                                          allTestsData[key].roundsData
                                        )[0] === "interview-Technical"
                                      ? interviewHRFemalePlaceholder
                                      : interviewTechnicalPlaceholder
                                  }
                                  alt="current-round-logo"
                                  width="100%"
                                  extraClass="rounded border card-img-top"
                                />
                                <div className="card-body p-0 tests-list-card-body">
                                  <div className="card-header text-center theme-12009d-color">
                                    {convertToTitleCase(key)}
                                  </div>
                                  <h6 className="card-title px-2">
                                    {convertToTitleCase(
                                      Object.keys(
                                        allTestsData[key].roundsData
                                      )[0]
                                    )}
                                  </h6>
                                  <p className="card-text px-2 ellipsis">
                                    {allTestsData[key].title}
                                  </p>
                                  <div className="ps-2">
                                    <RemainingTimeCounter
                                      startTime={
                                        allTestsData[key].accessTime.startTime
                                      }
                                      endTime={
                                        allTestsData[key].accessTime.endTime
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="card-footer  px-2  mt-3 text-end">
                                  <Link
                                    disabled={
                                      new Date(
                                        allTestsData[key].accessTime.startTime
                                      ) > new Date()
                                    }
                                    to={`evaluaitions/${key}`}
                                    className="rounded text-decoration-none"
                                  >
                                    View Details{" "}
                                    <FontAwesomeIcon
                                      className="me-3"
                                      icon={faArrowRight}
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </main>
    </div>
  );
};

export default LandingPage;
