import Editor from "@monaco-editor/react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import * as React from "react";
import ModalCloseButton from "../../../../Global/components/Buttons/ModalCloseButton/ModalCloseButton";
import CodeEvaluationMetricsAccordian from "../../Accordians/CodeEvaluationMetricsAccordian/CodeEvaluationMetricsAccordian";
import { reportDetailModalStyle } from "./ReportModalConstants";
import "./reportDetailsModal.css";

const editorOptions = {
  readOnly: true, // Set this to true to disable editing
};

export default function AlgoReportProblemDetailsModal({
  shouldOpenModel,
  setShouldOpenModel,
  dataForAccordian,
}) {
  AlgoReportProblemDetailsModal.propTypes = {
    shouldOpenModel: PropTypes.bool,
    setShouldOpenModel: PropTypes.func,
    dataForAccordian: PropTypes.object,
  };
  const handleClose = () => {
    setShouldOpenModel(false);
  };
  if (!shouldOpenModel) {
    return null;
  }
  const accordianStyle = {
    transform: `scale(0.9)`,
  };
  const formatTextWithBr = (text) => {
    return text.replace(/\\n\\n/g, "\n").replace(/\\n/g, "<br />");
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldOpenModel}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldOpenModel}>
        <Box sx={reportDetailModalStyle}>
          <div className="modal-content px-0 reportModalContent">
            <div
              className={`modal-header m-auto ${
                shouldOpenModel ? "reportModalFixedHeader p-2 p-md-4" : ""
              }`}
            >
              <span
                className="feedback_modal_heading text-xs md:text-base"
                style={{
                  color: "#5e6d9e",
                }}
              >
                Submission details:
              </span>
              <ModalCloseButton
                aria-label="CloseModal"
                onModalClose={() => setShouldOpenModel(false)}
              />
            </div>
            <div className="modal-body reportModalBody">
              <div
                dangerouslySetInnerHTML={{
                  __html: formatTextWithBr(dataForAccordian.dsa_question),
                }}
                className="dsa_report_modal_question"
              />
              <div className="row px-2 mx-0">
                <div className="col-lg-8 mx-0">
                  {dataForAccordian.userCode_data != "" && (
                    <Editor
                      className="mt-4"
                      value={dataForAccordian.userCode_data.replace(
                        /^\/\/code/,
                        ""
                      )}
                      defaultLanguage="cpp"
                      width={"100%"}
                      height={"350px"}
                      options={editorOptions}
                      theme="vs-light"
                      defaultValue={dataForAccordian.userCode_data.replace(
                        /^\/\/code/,
                        ""
                      )}
                      onChange={() => {}}
                    />
                  )}
                </div>
                {dataForAccordian.evaluation_data.Ratings && true && (
                  <div className="col-lg-4 px-0">
                    <span style={accordianStyle}>
                      <CodeEvaluationMetricsAccordian
                        ratingsData={dataForAccordian}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="dsa_report_modal_solution_redirect_banner mt-3">
              <h6 className="text-center dsa_report_modal_solution_redirect_banner_text">
                Jump to this{" "}
                <a
                  target="_blank"
                  href={`/dip-solution/${dataForAccordian.dsa_question_potd_id}`}
                  rel="noreferrer"
                >
                  solution page
                </a>{" "}
                to learn different ways to solve this problem
              </h6>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
