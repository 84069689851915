import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { PolarArea } from "react-chartjs-2";
import ModalCloseButton from "../../../../Global/components/Buttons/ModalCloseButton/ModalCloseButton";
import InterviewReportBanner from "../../Banners/InterviewReportBanner";
import ImagePlayer from "../../ImagePlayer/ImagePlayer";
import InterviewConvoDataCard from "./InterviewConvoDataCard/InterviewConvoDataCard";
import { reportDetailModalStyle } from "./ReportModalConstants";
import "./reportDetailsModal.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
  ArcElement
);
export default function InterviewReportDetailModal(
  shouldOpenNonCodingRoundReviewModel,
  setShouldOpenNonCodingRoundReviewModel,
  indexForModel,
  dataSourceForModel,
  interviewRecord
) {
  // eslint-disable-next-line no-unused-vars
  const radarOptions = {
    elements: {
      line: {
        borderWidth: 3,
      },
    },
  };
  const polarArcOptions = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldOpenNonCodingRoundReviewModel}
      onClose={() => {
        setShouldOpenNonCodingRoundReviewModel(false);
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldOpenNonCodingRoundReviewModel}>
        <Box sx={reportDetailModalStyle}>
          <div className="modal-content reportModalContent p-1 p-md-3">
            <div
              className={`modal-header m-auto ${
                shouldOpenNonCodingRoundReviewModel
                  ? "reportModalFixedHeader p-2 p-md-4"
                  : ""
              }`}
            >
              <span
                className="feedback_modal_heading text-xs md:text-xl"
                style={{
                  color: indexForModel % 2 == 0 ? "#5e6d9e" : "#ae866d",
                }}
              >
                {dataSourceForModel.replace("RoundReview", " round feedback")}
              </span>
              <ModalCloseButton
                aria-label="CloseModal"
                onModalClose={() =>
                  setShouldOpenNonCodingRoundReviewModel(false)
                }
              />
            </div>
            <div className="modal-body reportModalBody">
              <span className="css-12tgxhh-QuestionLink e5i1odf11 text-xs md:text-base justify-content-center">
                <div className="row w-100">
                  <div className="col-lg-8 text-justify text-xs md:text-base px-0 px-md-1">
                    <span>
                      <strong>Overall Summary:</strong>{" "}
                      {interviewRecord[dataSourceForModel].overall_summary}
                    </span>
                    <InterviewReportBanner
                      totalNumberOfQuestions={
                        interviewRecord[dataSourceForModel]
                          .totalNumberOfQuestions
                      }
                      averageScore={
                        interviewRecord[dataSourceForModel].averageScore
                      }
                    />
                    <InterviewConvoDataCard
                      data={interviewRecord[dataSourceForModel].question_rating}
                    />
                  </div>

                  <div className="col-lg-4 ">
                    <PolarArea
                      data={{
                        labels: [
                          ...interviewRecord[
                            dataSourceForModel
                          ].question_rating.map((data) => data.topic),
                        ],
                        datasets: [
                          {
                            label: "Factors",
                            data: [
                              ...interviewRecord[
                                dataSourceForModel
                              ].question_rating.map((data) => data.rating * 2),
                            ],
                            backgroundColor: [
                              "rgba(255, 99, 132, 0.5)",
                              "rgba(75, 192, 192, 0.5)",
                              "rgba(255, 205, 86, 0.5)",
                              "rgba(201, 203, 207, 0.5)",
                              "rgba(54, 162, 235, 0.5)",
                              "rgba(18, 0, 157, 0.5)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={polarArcOptions}
                    />
                    <div className="my-3">
                      <ImagePlayer
                        proctoringData={
                          interviewRecord[dataSourceForModel].proctoringData
                        }
                      />
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
