import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import ModalCloseButton from "../../Buttons/ModalCloseButton/ModalCloseButton";
import "./ExitFullscreenModal.css";

const FullscreenExitModal = ({
  show,
  onReject,
  onConfirm,
  text,
  heading,
  confirmBtnText,
  rejectBtntext,
  thirdBtnText,
  thirdBtnAction,
}) => {
  const isTabletSize = useIsTabletSize();

  return (
    <Modal centered show={show} onHide={onReject}>
      <div className="d-flex justify-content-between align-items-center modal-header py-2 form-heading">
        <p className="mb-0">{heading}</p>
        <ModalCloseButton onModalClose={onReject} />
      </div>
      <Modal.Body className="text-sm md:text-lg">{text}</Modal.Body>
      <Modal.Footer className="justify-content-between border-0">
        <button
          aria-label="Cancel"
          className={`btn btn-outline-primary  ${
            isTabletSize ? " " : "btn-sm"
          }`}
          onClick={onReject}
        >
          {rejectBtntext}
        </button>
        {thirdBtnText && (
          <button
            aria-label="Exit"
            className={`btn btn-outline-dark ${isTabletSize ? " " : "btn-sm"}`}
            onClick={thirdBtnAction}
          >
            {thirdBtnText}
          </button>
        )}
        <button
          aria-label="Exit"
          className={`btn btn-outline-danger  ${isTabletSize ? " " : "btn-sm"}`}
          onClick={onConfirm}
        >
          {confirmBtnText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FullscreenExitModal;

FullscreenExitModal.propTypes = {
  show: PropTypes.bool,
  onReject: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.string,
  heading: PropTypes.string,
  confirmBtnText: PropTypes.string,
  rejectBtntext: PropTypes.string,
  thirdBtnText: PropTypes.string,
  thirdBtnAction: PropTypes.func,
};
