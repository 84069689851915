import { useEffect, useState } from "react";

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showExitFullscreenAlertModal, setShowExitFullscreenAlertModal] =
    useState(false);

  const enterFullscreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch((error) => {
          console.error("Failed to enter fullscreen:", error);
        });
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
      setIsFullscreen(true);
    } else if (elem.webkitRequestFullscreen) {
      elem
        .webkitRequestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch((error) => {
          console.error("Failed to enter fullscreen:", error);
        });
    } else if (elem.msRequestFullscreen) {
      elem
        .msRequestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch((error) => {
          console.error("Failed to enter fullscreen:", error);
        });
    }
  };

  const exitFullscreen = () => {
    if (isFullscreen) {
      setShowExitFullscreenAlertModal(true);
    }
  };

  const handleConfirmExit = () => {
    setShowExitFullscreenAlertModal(false);
    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch((error) => {
            console.error("Failed to exit fullscreen:", error);
          });
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        setIsFullscreen(false);
      } else if (document.webkitExitFullscreen) {
        document
          .webkitExitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch((error) => {
            console.error("Failed to exit fullscreen:", error);
          });
      } else if (document.msExitFullscreen) {
        document
          .msExitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch((error) => {
            console.error("Failed to exit fullscreen:", error);
          });
      }
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    document.addEventListener(
      "webkitfullscreenchange",
      fullscreenChangeHandler
    );
    document.addEventListener("mozfullscreenchange", fullscreenChangeHandler);
    document.addEventListener("MSFullscreenChange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
      document.removeEventListener(
        "webkitfullscreenchange",
        fullscreenChangeHandler
      );
      document.removeEventListener(
        "mozfullscreenchange",
        fullscreenChangeHandler
      );
      document.removeEventListener(
        "MSFullscreenChange",
        fullscreenChangeHandler
      );
    };
  }, []);

  return {
    isFullscreen,
    enterFullscreen,
    exitFullscreen,
    showExitFullscreenAlertModal,
    setShowExitFullscreenAlertModal,
    handleConfirmExit,
  };
};

export default useFullscreen;
