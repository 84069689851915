import {
  faPause,
  faPlay,
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "./ImagePlayer.css"; // Import your custom CSS file for styling

const ImagePlayer = ({ proctoringData = [] }) => {
  const [playing, setPlaying] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (playing) {
      intervalRef.current = setInterval(() => {
        if (currentPhotoIndex === proctoringData.length - 1) {
          console.log(
            "DWaraka last photo currentPhotoIndex: ",
            currentPhotoIndex
          );
          setCurrentPhotoIndex(0);
        } else {
          setCurrentPhotoIndex(
            (prevIndex) => (prevIndex + 1) % proctoringData.length
          );
        }
      }, 500); // Change the duration here for slideshow speed
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [playing, proctoringData, currentPhotoIndex]);

  useEffect(() => {
    if (isVideoStarted) {
      setProgress((currentPhotoIndex + 1) / proctoringData.length);
    } else {
      setIsVideoStarted(true);
    }
  }, [currentPhotoIndex, proctoringData]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleProgressChange = (e) => {
    const percent = e.target.value * 100;
    const step = 100 / proctoringData.length;
    setProgress(e.target.value);
    const newIndex = Math.floor(percent / step);
    setCurrentPhotoIndex(Math.min(newIndex, proctoringData.length - 1));
  };

  const handlePrev = () => {
    setCurrentPhotoIndex(
      (prevIndex) =>
        (prevIndex - 1 + proctoringData.length) % proctoringData.length
    );
  };

  const handleNext = () => {
    setCurrentPhotoIndex(
      (prevIndex) => (prevIndex + 1) % proctoringData.length
    );
  };

  return (
    <div className="photo-slideshow-container border rounded">
      {proctoringData?.length < 1 ? (
        <div>No proctoring data found</div>
      ) : (
        <div>
          <div className="photo-wrapper border">
            <span className="badge-text score rounded-pill px-2 px-md-3 badge">
              Copy:{" "}
              {parseFloat(proctoringData[currentPhotoIndex].response.copied)}%
            </span>
            <img
              src={proctoringData[currentPhotoIndex].screenshot}
              style={{ width: "350px", height: "auto" }}
              alt={` ${currentPhotoIndex + 1}`}
            />
          </div>
          <div className="timeline-and-controls">
            <div className="progress-bar-container">
              <div className="progress-segments-container">
                {proctoringData.map((photo, index) => (
                  <div
                    key={index}
                    className="progress-segment"
                    style={{
                      backgroundColor: `${
                        parseFloat(photo.response.copied) >= 60
                          ? "rgba(225, 0, 0, 0.7)"
                          : parseFloat(photo.response.copied) >= 40
                          ? "orange"
                          : "rgba(0, 225, 0, 0.2)"
                      }`,
                      width: `${350 / proctoringData.length}px`,
                    }}
                  >
                    {/* {photo.response.copied} */}
                  </div>
                ))}
              </div>
              <input
                type="range"
                min={0}
                max={1}
                step="any"
                value={progress}
                className="progress-bar rounded"
                onChange={handleProgressChange}
                style={{
                  background: "transparent",
                  zIndex: "2",
                  position: "relative",
                }}
              />
            </div>
            <div className="playback-controls">
              <button onClick={handlePrev} className="control-btn">
                <FontAwesomeIcon icon={faStepBackward} />
              </button>
              <button onClick={handlePlayPause} className="py-0 control-btn">
                {playing ? (
                  <FontAwesomeIcon icon={faPause} className="play-icon" />
                ) : (
                  <FontAwesomeIcon icon={faPlay} className="pause-icon" />
                )}
              </button>
              <button onClick={handleNext} className="control-btn">
                <FontAwesomeIcon icon={faStepForward} />
              </button>
            </div>
            {/* <div className="duration-teller">{Math.floor(progress * 100)}%</div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePlayer;
ImagePlayer.propTypes = {
  proctoringData: PropTypes.array.isRequired,
};
