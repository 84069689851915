import FeedIcon from "@mui/icons-material/Feed";
import PropTypes from "prop-types";
import React from "react";
export default function CodingReportQuestionsListItemComp({
  roundData,
  index,
  setIndexForModel,
  setDataSourceForModel,
  setShouldOpenNonCodingRoundReviewModel,
}) {
  return (
    <div className="css-xkn2kk-QuestionItemContainer e5i1odf7 reports-dsa-quesion-box p-1 px-md-2">
      <FeedIcon sx={{ color: index % 2 == 0 ? "#5e6d9e" : "#ae866d" }} />
      <div className="css-1n4xofq-QuestionItemInfo e5i1odf9">
        <span className="css-12tgxhh-QuestionLink e5i1odf11 text-xs md:text-base justify-content-center">
          Question - {index + 1}
        </span>
        <span className="theme-12009d-color">
          Passed: <strong>{roundData.bestScore} / 10</strong>
        </span>
        <span className="theme-12009d-color">
          <strong>{roundData.selectedLanguage}</strong>
        </span>
      </div>
      <div className="css-cwdo40-QuestionItemDetails e5i1odf10 text-xs md:text-base ms-1">
        <span
          type="button"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          data-question-idx="0"
          style={{ color: "#5e6d9e" }}
          onClick={() => {
            setIndexForModel(index);
            setDataSourceForModel(roundData);
            setShouldOpenNonCodingRoundReviewModel(true);
          }}
        >
          Details
        </span>
      </div>
    </div>
  );
}
CodingReportQuestionsListItemComp.propTypes = {
  roundData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setIndexForModel: PropTypes.func.isRequired,
  setDataSourceForModel: PropTypes.func.isRequired,
  setShouldOpenNonCodingRoundReviewModel: PropTypes.func.isRequired,
};
