import { toast } from "react-toastify";
import { performAPIRequest } from "../../Consumer/supportFunctions/InterviewBotFunctions";
import { avatarVoiceMappings } from "../components/InterviewBot/InterviewBotConstants/InterviewBotConstants";
import {
  eventTypes,
  logAnalyticsErrorEvent,
} from "./Analytics/firebaseAnalytics";
import { handleMediaPlayError } from "./helperFunctions";
export const handleSynthesizeSpeech = async ({ text, avatarName }) => {
  try {
    let response;
    for (let i = 0; i < 2; i++) {
      response = await performAPIRequest({
        endPoint: "synthesize-speech",
        body: JSON.stringify({
          text: text,
          languageCode: avatarVoiceMappings[avatarName]["languageCode"],
          ssmlGender: avatarVoiceMappings[avatarName]["ssmlGender"],
        }),
      });
      if (response.status === 200) break;
      logAnalyticsErrorEvent({
        eventType: eventTypes.text_to_speech_api_failure,
        eventData: {
          statusCode: response.status,
          endPoint: "synthesize-speech",
          api_call_type: "FE_text_to_speech_Error",
        },
      });
    }

    if (response.status !== 200) {
      console.error(
        "Error fetching speech with status code: ",
        response.status
      );
      toast.error("Error fetching speech data");
      return [];
    }

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const mediaSource = audioContext.createMediaElementSource(new Audio());
    const mediaStreamDestination = audioContext.createMediaStreamDestination();
    mediaSource.connect(mediaStreamDestination);

    const reader = response.body.getReader();
    var audioURLs = [];

    const pump = async () => {
      return reader
        .read()
        .then(async ({ value, done }) => {
          if (done) {
            console.log("Stream complete");
            return;
          }

          const audioBuffer = new Uint8Array(value);
          const audioData = audioBuffer.reduce((data, byte) => {
            data.push(byte);
            return data;
          }, []);

          const audioBlob = new Blob([new Uint8Array(audioData)], {
            type: "audio/mpeg",
          });
          const audioURL = URL.createObjectURL(audioBlob);

          audioURLs.push(audioURL);
          //   await playAudio(audioURL);

          return pump();
        })
        .catch((error) => {
          console.error("Error reading stream:", error);
        });
    };
    await pump();
    return audioURLs;
  } catch (error) {
    console.error("Error fetching data:", error);
    toast.error("Error fetching speech data");
    return [];
  }
};

export const playAudio = async (audioURL, setCurrentPlayingAudio) => {
  try {
    const audio = new Audio(audioURL);
    if (!audio) {
      console.log("DWaraka SIri Failed to create audio element.");
    }
    await audio.play();
    setCurrentPlayingAudio(audio);
    await new Promise((resolve) => {
      audio.addEventListener("ended", resolve);
    });
  } catch (error) {
    handleMediaPlayError(error, "bot_audio");
  }
};

export const stopAudio = ({ currentPlayingAudio, setCurrentPlayingAudio }) => {
  try {
    if (currentPlayingAudio) {
      currentPlayingAudio.pause(); // Pause the currently playing audio if any
      setCurrentPlayingAudio(null); // Reset currentAudio
    }
  } catch (error) {
    console.error("Error occurred while pausing video:", error);
  }
};
