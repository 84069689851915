import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { formatTimestamp } from "../../../Consumer/supportFunctions/GeneralHelperFunctions";
import ReportForCodingRoundComponent from "../../../Global/components/ReportComponents/CodingRoundReportComponent/CodingRoundReportComponent";
import ReportForASingleInterviewComponent from "../../../Global/components/ReportComponents/InterviewRoundReportComponent/InterviewRoundReportComponent";
import ReportForASingleOnlineTest from "../../../Global/components/ReportComponents/OnlineTestReportComponent/OnlineTestReportComponent";
import { formatTimeToString } from "../../../Global/utils/stringExtensionFunctions";
import RoundReportModal from "../Modals/RoundReportModal/RoundReportModal";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sortable: false,
  },
  {
    id: "overAllScorePercentage",
    numeric: true,
    disablePadding: false,
    label: "Score%",
    sortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "status",
    sortable: false,
  },
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Date & Time",
    sortable: false,
  },
  {
    id: "timeTaken",
    numeric: false,
    disablePadding: false,
    label: "Time Taken",
    sortable: false,
  },
  {
    id: "details",
    numeric: false,
    disablePadding: false,
    label: "Details",
    sortable: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ rows = [], roundType }) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("overAllScorePercentage");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shouldShowReportModal, setShouldShowReportModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalChild, setModalChild] = useState(<></>);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.email}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal">{index + 1}</TableCell>
                    <TableCell
                      component="th"
                      id={index}
                      scope="row"
                      padding="none"
                    >
                      {row.email}
                    </TableCell>
                    <TableCell align="center">
                      {isNaN(row.overAllScorePercentage)
                        ? 0
                        : Number(Number(row.overAllScorePercentage).toFixed(2))}
                      %
                    </TableCell>
                    <TableCell align="center">
                      {row.isThisEntirelyDone ? "Completed" : "Incomplete"}
                    </TableCell>
                    <TableCell align="center">
                      {formatTimestamp(row.timestamp).formattedDate}
                    </TableCell>
                    <TableCell align="center">
                      {formatTimeToString(row?.totalTimeTaken)}
                    </TableCell>
                    <TableCell
                      className="normal text-primary cursor-pointer"
                      onClick={() => {
                        setModalTitle(`${row.email} - Detailed Report`);
                        setModalChild(
                          roundType.startsWith("onlineTest") ? (
                            <ReportForASingleOnlineTest
                              onlineTestRecord={row}
                              onlineTestId={row.id}
                              showShare={false}
                              showBackBtn={false}
                            />
                          ) : roundType === "codingRound" ? (
                            <ReportForCodingRoundComponent
                              codingReportData={row}
                              codingRoundId={row.id}
                              showShare={false}
                            />
                          ) : (
                            <ReportForASingleInterviewComponent
                              interviewRecord={row}
                              interViewId={row.id}
                              shouldShowCertificate={false}
                              showBackBtn={false}
                            />
                          )
                        );
                        setShouldShowReportModal(true);
                      }}
                    >
                      Details
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {shouldShowReportModal && (
        <RoundReportModal
          shouldShowReportModal={shouldShowReportModal}
          setShouldShowReportModal={setShouldShowReportModal}
          title={modalTitle}
        >
          {modalChild}
        </RoundReportModal>
      )}
    </Box>
  );
}
EnhancedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  roundType: PropTypes.string.isRequired,
};
