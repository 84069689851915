import { getAnalytics, logEvent } from "@firebase/analytics";
import { child, get, ref as ref1, set, update } from "firebase/database";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, fStore } from "../../../firebase";
const analytics = getAnalytics();

const logAnalyticsClickEvent = ({ api_call_type, error_msg }) => {
  logEvent(analytics, "firebase_failure", {
    api_call_type: api_call_type,
    error_message: error_msg,
  });
};

export async function addNewPOTDDetailsToRealTimeDB(
  potdQuestionUid,
  data,
  realTimeDBPath
) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    // Check if the question already exists in the list
    var emailMap = {};
    emailMap = snapshot.val() || {};

    if (!emailMap[potdQuestionUid]) {
      emailMap[potdQuestionUid] = data;

      // Use 'set' to update the data in the database
      await set(ref1(db, realTimeDBPath), emailMap);
    } else {
      emailMap[potdQuestionUid] = data;
      await set(ref1(db, realTimeDBPath), emailMap);
    }
  } catch (error) {
    console.error("Error adding email to subscription list:", error);
    logAnalyticsClickEvent({
      api_call_type: "question_addition_to_realtime_db",
      error_msg: error.message,
    });
  }
}

export async function increasePotdSubmissionCount(
  path,
  variableToChange,
  incrementBy,
  userId
) {
  if (!userId) {
    return Promise.reject(new Error("User ID is required to edit data."));
  }

  try {
    const keyRef = child(ref1(db, path), variableToChange);
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const variablePathObject = {};
      variablePathObject[variableToChange] = snapshot.val() + incrementBy;

      await update(ref1(db, path), variablePathObject);
    }
    return userId;
  } catch (error) {
    console.error("Error editing value:", error);
    logAnalyticsClickEvent({
      api_call_type: `${path} _ ${variableToChange}`,
      error_msg: error.message,
    });

    return Promise.reject(error);
  }
}

export async function addNewQuestionToFirestore({
  question_uid,
  new_question_data,
}) {
  console.log("Dwaraka: ", question_uid);

  try {
    const collectionPath = collection(fStore, "dSA_question_bank");
    const documentRef = doc(collectionPath, question_uid);
    const docSnap = await getDoc(documentRef);
    const dataWithTimestamp = {
      ...new_question_data,
      timestamp: serverTimestamp(),
    };

    if (docSnap.exists()) {
      await updateDoc(documentRef, dataWithTimestamp);
    } else {
      await setDoc(documentRef, dataWithTimestamp);
    }
  } catch (error) {
    console.error("Error adding notification: ", error);
    logAnalyticsClickEvent({
      api_call_type: "question_addition_to_firestore",
      error_msg: error.message,
    });
  }
}

export async function fetchPotdFromFireStore({
  parent_collection,
  parent_document,
}) {
  try {
    const documentRef = doc(fStore, parent_collection, parent_document);
    const docSnap = await getDoc(documentRef);
    if (docSnap.exists()) {
      const datanew = docSnap.data();
      return datanew;
    } else {
      console.log("Document does not exist");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null;
  }
}

export async function addUserDIPSubmissionToFirestore({
  potdQuestionUid,
  userSubmittedCode,
  evaluation_data,
  user_uid,
}) {
  try {
    var collectionPath = collection(
      fStore,
      "daily-interview-problem-submissions",
      user_uid,
      "evaluAItor"
    );
    const documentRef = doc(collectionPath, potdQuestionUid.toString());

    await setDoc(documentRef, {
      potdQuestionUid: potdQuestionUid,
      userSubmittedCode: userSubmittedCode,
      evaluation_data: evaluation_data,
      timestamp: serverTimestamp(),
    });
  } catch (error) {
    console.error(
      "Error adding dip submission data: ",
      error.message,
      error.code
    );
    logAnalyticsClickEvent({
      api_call_type: "adding_dip_submission",
      error_msg: error.message,
    });
  }
}

export async function addDSAQuestionToCategoriesList(data, realTimeDBPath) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    // Check if the email already exists in the list
    var existingList = [];
    if (snapshot.exists()) {
      existingList = snapshot.val() || [];

      if (!existingList.includes(data)) {
        existingList.push(data);

        // Use 'set' to update the data in the database
        await set(ref1(db, realTimeDBPath), existingList);
      } else {
        console.log("Question id already exists in the list:", data);
      }
    } else {
      existingList.push(data);
      await set(ref1(db, realTimeDBPath), existingList);
    }
  } catch (error) {
    console.error("Error adding question if to category list:", error);
    logAnalyticsClickEvent({
      api_call_type: `adding_dip_id_to_${realTimeDBPath}`,
      error_msg: error.message,
    });
  }
}
