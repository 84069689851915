import PropTypes from "prop-types";
import React from "react";
import "./ReportsMessageBubble.css";

export default function ReportsMessageBubble({ message, sender, badgeText }) {
  ReportsMessageBubble.propTypes = {
    message: PropTypes.any,
    sender: PropTypes.string,
    badgeText: PropTypes.string,
  };
  return (
    <div className={`report-message-bubble-container ${sender}`}>
      <span className="report-message-bubble position-relative px-2 pb-1 pt-3 px-md-3 pb-md-2 md:w-5/6">
        <span className="report-message-text text-xs md:text-base">
          <span className="badge-text rounded-pill badge">{badgeText}</span>
          {message}
        </span>
      </span>
    </div>
  );
}
