import { performAPIRequest } from "../../Consumer/supportFunctions/InterviewBotFunctions";
export const handleSendMail = async ({ body }) => {
  try {
    const response = await performAPIRequest({
      endPoint: "send-mail",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
