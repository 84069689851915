import PropTypes from "prop-types";
import React from "react";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import { convertToTitleCase } from "../../../utils/stringExtensionFunctions";
import ImagePlayer from "../../ImagePlayer/ImagePlayer";

export default function ReportOverAllSummaryTextComponent({
  formattedDate,
  roundScore,
  incorrectQuestions,
  unattemptedQuestions,
  proctoringData,
  recordedUrl,
  onlineTestId,
  userName,
}) {
  const isTabletSize = useIsTabletSize();

  return (
    <div className="text-start flex flex-col gap-1">
      {userName && (
        <p className="report_card_heading d-block text-xs md:text-base">
          <span
            style={{
              width: isTabletSize ? "150px" : "120px",
              display: "inline-block",
            }}
            className="font"
          >
            {" "}
            Name:
          </span>{" "}
          {convertToTitleCase(userName)}
        </p>
      )}
      {onlineTestId && (
        <p className="report_card_heading d-block text-xs md:text-base">
          <span
            style={{
              width: isTabletSize ? "150px" : "120px",
              display: "inline-block",
            }}
            className="font"
          >
            Test ID:{" "}
          </span>{" "}
          {onlineTestId}
        </p>
      )}
      <p className="report_card_heading d-block text-xs md:text-base">
        <span
          style={{
            width: isTabletSize ? "150px" : "120px",
            display: "inline-block",
          }}
          className="font"
        >
          Taken on:{" "}
        </span>{" "}
        {formattedDate}
      </p>
      <p className="report_card_heading d-block text-xs md:text-base">
        <span
          style={{
            width: "150px",
            display: "inline-block",
          }}
          className="font"
        >
          Score:
        </span>{" "}
        {roundScore}/{roundScore + incorrectQuestions + unattemptedQuestions}
      </p>
      <hr />
      {!proctoringData?.length && !recordedUrl ? (
        <div className="flex flex-col gap-1">
          <p className="report_card_heading d-block text-xs md:text-base">
            <span
              style={{
                width: isTabletSize ? "150px" : "120px",
                display: "inline-block",
              }}
              className="font"
            >
              Total Questions:
            </span>{" "}
            {roundScore + incorrectQuestions + unattemptedQuestions}
          </p>
          <p className="report_card_heading d-block text-xs md:text-base">
            <span
              style={{
                width: isTabletSize ? "150px" : "120px",
                display: "inline-block",
              }}
              className="font"
            >
              Correct:
            </span>{" "}
            <span
              style={{
                color: "rgba(75, 192, 192, 1)",
              }}
            >
              {roundScore}
            </span>
          </p>

          <p className="report_card_heading d-block text-xs md:text-base">
            <span
              style={{
                width: "150px",
                display: "inline-block",
              }}
              className="font"
            >
              Incorrect:
            </span>{" "}
            <span
              style={{
                width: "150px",
                color: "rgba(255, 99, 132, 1)",
              }}
            >
              {incorrectQuestions}
            </span>
          </p>
          <p className="report_card_heading d-block text-xs md:text-base">
            <span
              style={{
                width: "150px",
                display: "inline-block",
              }}
              className="font"
            >
              Unattempted:
            </span>{" "}
            <span
              style={{
                width: "150px",
                display: "inline-block",
                color: "rgba(255, 206, 86, 1)",
              }}
            >
              {" "}
              {unattemptedQuestions}
            </span>
          </p>
          <p className="report_card_heading d-block text-xs md:text-base">
            <span
              style={{
                width: isTabletSize ? "150px" : "120px",
                display: "inline-block",
              }}
              className="font"
            >
              {" "}
              Time taken:
            </span>{" "}
            Coming soon
          </p>
        </div>
      ) : (
        <div className="my-3 row d-flex justify-content-center align-items-center">
          {proctoringData?.length > 0 && (
            <div
              className="my-3 col-lg-6 d-flex justify-content-center align-items-start"
              style={{ transform: "scale(0.75)" }}
            >
              <ImagePlayer proctoringData={proctoringData} />
            </div>
          )}
          {recordedUrl && (
            <div className="my-3 col-lg-6 d-flex justify-content-center align-items-start">
              <video className="w-100" src={recordedUrl} controls />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
ReportOverAllSummaryTextComponent.propTypes = {
  formattedDate: PropTypes.string.isRequired,
  roundScore: PropTypes.number.isRequired,
  incorrectQuestions: PropTypes.number.isRequired,
  unattemptedQuestions: PropTypes.number.isRequired,
  proctoringData: PropTypes.array,
  recordedUrl: PropTypes.string,
  onlineTestId: PropTypes.string,
  userName: PropTypes.string,
};
