import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import Chip from "@mui/material/Chip";
import React from "react";
import { roundStatus } from "../../InterviewBot/InterviewBotConstants/InterviewBotConstants";
const InterviewReportDSARoundsListItemComp = ({
  dataForQuestions,
  n,
  interviewRecord,
  setDataForAccordian,
  setShouldOpenModel,
}) => {
  const questionComponents = [];

  for (let index = 0; index < n; index++) {
    const questionData = dataForQuestions && dataForQuestions[index];

    questionComponents.push(
      <div
        key={index} // Make sure to use a unique key for each element
        className={`css-xkn2kk-QuestionItemContainer e5i1odf7 reports-dsa-quesion-box p-1 px-md-2 ${
          interviewRecord[`DSAQ${index + 1}Status`] != roundStatus.Completed
            ? "opacity-50"
            : ""
        }`}
      >
        <IntegrationInstructionsIcon sx={{ color: "#225e87" }} />
        <div className="css-1n4xofq-QuestionItemInfo e5i1odf9">
          <span className="css-12tgxhh-QuestionLink e5i1odf11 text-xs md:text-base">
            {`Question - ${index + 1}`}
          </span>
          <div className="css-vjt3mz-TestcaseProgressContainer e5i1odf0">
            <span className="css-oqomka-TestcaseProgressDetails e5i1odf1">
              {(interviewRecord.DSAQuestionsScoresArray &&
                interviewRecord.DSAQuestionsScoresArray[index] * 2) ||
                0}{" "}
              / 10
            </span>
          </div>
        </div>
        {interviewRecord[`DSAQ${index + 1}Status`] === roundStatus.Completed ? (
          <div className="css-cwdo40-QuestionItemDetails e5i1odf10 text-xs md:text-base ms-1">
            <span
              data-question-idx={index}
              onClick={() => {
                setDataForAccordian(questionData.data),
                  setShouldOpenModel(true);
              }}
              style={{ color: "#225e87" }}
            >
              Details
            </span>
          </div>
        ) : interviewRecord.DSAQuestionsScoresArray &&
          interviewRecord.DSAQuestionsScoresArray[index] ? (
          <span
            data-question-idx={index}
            onClick={() => {
              setDataForAccordian(questionData.data), setShouldOpenModel(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <Chip
              variant="outlined"
              color="info"
              size="small"
              label="Incomplete"
            />
          </span>
        ) : (
          <Chip
            variant="outlined"
            color="warning"
            size="small"
            label="Unattempted"
          />
        )}
      </div>
    );
  }

  return questionComponents;
};

export default InterviewReportDSARoundsListItemComp;
