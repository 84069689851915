export const resumeEvaluaitionAllParameters = [
  "Contact Information",
  "Educational Qualifications",
  "Work Experience",
  "Technical Skills",
  "Soft Skills",
  "Keywords Evaluation",
  "Strengths",
  "Weaknesses",
  "Actionable Recommendations",
];
export const resumeEvaluaitionScoreParameters = [
  "Technical Skills",
  "Work Experience",
  "Educational Qualifications",
  "Soft Skills",
  "Contact Information",
  "Keywords Evaluation",
];

export const resumeEvaluaitionRangeParameters = [
  "Technical Skills",
  "Soft Skills",
  "Keywords Evaluation",
];

export const resumeEvaluaitionRadioParameters = [
  "Work Experience",
  "Educational Qualifications",
];

export const resumeEvaluaitionStringArrayParameters = [
  "Actionable Recommendations",
  "Strengths",
  "Weaknesses",
];
export const dummyUserData = {
  email: "sevaluaitor@gmail.com",
  uid: "eVwvSkzUg1dSiWXIuAaLu4y89TA2",
  profilepic:
    "https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/userProfilePics%2Fevaluaitor-support01?alt=media&token=ede27767-be16-4b11-bb92-3ae42e371377",
  displayName: "User",
  profileId: "evaluaitor-user81",
  userReferralCode: "ei72uQ",
  unSeenNotificationsCount: 0,
  credits: 0,
};
export const roundsToFirestoreCollectionIdMap = {
  interview_with_ai_submissions: "Interview",
  online_test_submissions: "Online test",
  coding_round_submissions: "Coding Round",
  hiring_attempt_submissions: "Complete Hiring Process",
  WIPRO_Quantitative_Aptitude: "Quant Aptitude",
  WIPRO_Logical_Reasoning: "Logical Reasoning",
  WIPRO_Verbal_English: "Verbal English",
  TCS_Verbal_Ability: "Verbal Ability",
  TCS_Numerical_Ability: "Numerical Ability",
  TCS_Logical_Ability: "Logical Ability",
  TCS_Advance_Aptitude: "Advance Aptitude",
  TCS_Advanced_Reasoning_Ability: "Advanced Reasoning",
  Infosys_Reasoning_Ability: "Reasoning Ability",
  Infosys_Mathematical_Ability: "Mathematical Ability",
  Infosys_Verbal_Ability: "Verbal Ability",
  Infosys_PseudoCode: "Pseudo Code",
  Infosys_Puzzle_Solving: "Puzzle Solving",
};
