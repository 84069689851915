import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import ModalCloseButton from "../../Buttons/ModalCloseButton/ModalCloseButton";
import { reportDetailModalStyle } from "./ReportModalConstants";
import ReportOverAllSummaryTextComponent from "./ReportOverAllSummaryTextComponent";
import "./reportDetailsModal.css";
export default function OnlineTestReportDetailModal(
  shouldOpenNonCodingRoundReviewModel,
  setShouldOpenNonCodingRoundReviewModel,
  indexForModel,
  dataSourceForModel
) {
  const isTabletSize = useIsTabletSize();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={shouldOpenNonCodingRoundReviewModel}
      onClose={() => {
        setShouldOpenNonCodingRoundReviewModel(false);
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={shouldOpenNonCodingRoundReviewModel}>
        <Box sx={reportDetailModalStyle}>
          <div className="modal-content reportModalContent overflow-x-hidden mont-500">
            <div
              className={`modal-header m-auto ${
                shouldOpenNonCodingRoundReviewModel
                  ? "reportModalFixedHeader p-2 p-md-4"
                  : ""
              }`}
            >
              <span
                style={{
                  color: indexForModel % 2 == 0 ? "#5e6d9e" : "#ae866d",
                }}
              >
                <h4 className="text-center text-primary ">
                  <span
                    className="feedback_modal_heading text-xs md:text-base"
                    style={{
                      color: dataSourceForModel.isQualified
                        ? "rgba(75, 192, 192, 1)"
                        : "rgba(255, 99, 132, 1)",
                    }}
                  >
                    {dataSourceForModel.roundTitle
                      .split("_")
                      .slice(1)
                      .join(" ")}{" "}
                    Round: {dataSourceForModel.roundScore}/
                    {Object.keys(
                      dataSourceForModel.roundQuestions
                    ).length.toString()}
                  </span>
                </h4>
              </span>
              <h5 className="feedback_modal_round_status ms-2">
                <small>
                  {dataSourceForModel.isQualified ? (
                    <span className="text-success">
                      Qualified <FontAwesomeIcon icon={faCircleCheck} />{" "}
                    </span>
                  ) : (
                    <span className="text-danger">
                      Disqualified <FontAwesomeIcon icon={faCircleXmark} />{" "}
                    </span>
                  )}
                </small>
              </h5>
              <ModalCloseButton
                aria-label="CloseModal"
                onModalClose={() => {
                  setShouldOpenNonCodingRoundReviewModel(false);
                }}
              />
            </div>
            <div className="modal-body reportModalBody">
              <div className="row">
                <div className="col-lg-6">
                  <ReportOverAllSummaryTextComponent
                    formattedDate={dataSourceForModel.formattedDate}
                    roundScore={dataSourceForModel.roundScore}
                    incorrectQuestions={dataSourceForModel.incorrectQuestions}
                    unattemptedQuestions={
                      dataSourceForModel.unattemptedQuestions
                    }
                    recordedUrl={dataSourceForModel.recordedUrl}
                    proctoringData={dataSourceForModel.proctoringData}
                    onlineTestId={dataSourceForModel.onlineTestId}
                  />
                </div>{" "}
                <div className="col-lg-6 d-flex  ">
                  <div style={{ height: isTabletSize ? "300px" : "150px" }}>
                    <Doughnut
                      data={{
                        labels: ["Correct", "Incorrect", "Unattempted"],
                        datasets: [
                          {
                            data: [
                              dataSourceForModel.roundScore,
                              dataSourceForModel.incorrectQuestions,
                              dataSourceForModel.unattemptedQuestions,
                            ],
                            backgroundColor: [
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(255, 206, 86, 0.2)",
                            ],
                            borderColor: [
                              "rgba(75, 192, 192, 1)",
                              "rgba(255, 99, 132, 1)",
                              "rgba(255, 206, 86, 1)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <span className="css-12tgxhh-QuestionLink e5i1odf11 d-block text-xs md:text-base">
                <div>
                  {Object.keys(dataSourceForModel.roundQuestions).map(
                    (questionId, questionIndex) => (
                      <div key={questionIndex}>
                        <div
                          className={`results-question-container ${
                            dataSourceForModel.roundQuestions[questionId]
                              .userChosenOption === "NA"
                              ? "unAttempted"
                              : dataSourceForModel.roundQuestions[questionId]
                                  .userChosenOption ===
                                dataSourceForModel.roundQuestions[questionId]
                                  .correctAnswerIndex
                              ? "correctAnswer"
                              : "wrongAnswer"
                          }`}
                        >
                          <p>
                            <strong className="theme-12009d-color mb-3">
                              Question {questionIndex + 1}:{" "}
                            </strong>{" "}
                            <div
                              className="mt-2"
                              dangerouslySetInnerHTML={{
                                __html:
                                  dataSourceForModel.roundQuestions[questionId]
                                    .question,
                              }}
                            />
                          </p>

                          {dataSourceForModel.roundQuestions[questionId]
                            .userChosenOption !== "NA" ? (
                            dataSourceForModel.roundQuestions[questionId]
                              .userChosenOption ===
                            dataSourceForModel.roundQuestions[questionId]
                              .correctAnswerIndex ? (
                              <p>
                                Answer:{" "}
                                {
                                  dataSourceForModel.roundQuestions[questionId]
                                    .correctAnswerIndex
                                }
                                <span className="ps-3">
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{ color: "#2ed6c4" }}
                                  />
                                </span>
                              </p>
                            ) : (
                              <>
                                <p>
                                  User&apos;s Answer:{" "}
                                  {
                                    dataSourceForModel.roundQuestions[
                                      questionId
                                    ].options[
                                      dataSourceForModel.roundQuestions[
                                        questionId
                                      ].userChosenOption
                                    ]
                                  }
                                  <span className="ps-3">
                                    <FontAwesomeIcon
                                      icon={faCircleXmark}
                                      style={{ color: "#f55c6f" }}
                                    />
                                  </span>{" "}
                                </p>
                                <p>
                                  Correct Answer:{" "}
                                  {
                                    dataSourceForModel.roundQuestions[
                                      questionId
                                    ].options[
                                      dataSourceForModel.roundQuestions[
                                        questionId
                                      ].correctAnswerIndex
                                    ]
                                  }
                                </p>
                              </>
                            )
                          ) : (
                            <p>
                              Correct Answer:{" "}
                              {
                                dataSourceForModel.roundQuestions[questionId]
                                  .options[
                                  dataSourceForModel.roundQuestions[questionId]
                                    .correctAnswerIndex
                                ]
                              }{" "}
                              <span className="ps-3">
                                <FontAwesomeIcon
                                  icon={faCircleExclamation}
                                  style={{ color: "#e8b017" }}
                                />
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </span>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
