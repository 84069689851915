import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FeedIcon from "@mui/icons-material/Feed";
import PropTypes from "prop-types";
import React from "react";
import { roundsToFirestoreCollectionIdMap } from "../../../../Consumer/Assets/Constants";

export default function OnlineTestReportRoundsListItemComp({
  roundData,
  index,
  setIndexForModel,
  setDataSourceForModel,
  detailedRoundsData,
  formattedDate,
  setShouldOpenNonCodingRoundReviewModel,
}) {
  return (
    <div
      className="css-xkn2kk-QuestionItemContainer e5i1odf7 reports-dsa-quesion-box p-1 px-md-2"
      key={roundData.roundTitle}
    >
      <FeedIcon sx={{ color: index % 2 == 0 ? "#5e6d9e" : "#ae866d" }} />
      <div className="css-1n4xofq-QuestionItemInfo e5i1odf9">
        <span className="css-12tgxhh-QuestionLink e5i1odf11 text-xs md:text-base">
          {roundsToFirestoreCollectionIdMap[roundData.roundTitle]} feedback
        </span>
        <p className="mb-0">
          {roundData.isQualified ? (
            <span className="text-success">
              Qualified <FontAwesomeIcon icon={faCircleCheck} />{" "}
            </span>
          ) : (
            <span className="text-danger">
              Disqualified <FontAwesomeIcon icon={faCircleXmark} />{" "}
            </span>
          )}
        </p>
      </div>

      <div className="css-cwdo40-QuestionItemDetails e5i1odf10 text-xs md:text-base ms-1">
        <span
          type="button"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          data-question-idx="0"
          style={{ color: "#5e6d9e" }}
          onClick={() => {
            setIndexForModel(index);
            setDataSourceForModel({
              ...detailedRoundsData[index],
              formattedDate: formattedDate,
            });
            setShouldOpenNonCodingRoundReviewModel(true);
          }}
        >
          Details
        </span>
      </div>
    </div>
  );
}
OnlineTestReportRoundsListItemComp.propTypes = {
  roundData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setIndexForModel: PropTypes.func.isRequired,
  setDataSourceForModel: PropTypes.func.isRequired,
  detailedRoundsData: PropTypes.object.isRequired,
  formattedDate: PropTypes.string.isRequired,
  setShouldOpenNonCodingRoundReviewModel: PropTypes.func.isRequired,
};
