import {
  faChartBar,
  faChartPie,
  faCircleInfo,
  faFileLines,
  faUserPlus,
  faUsersSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Zoom } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Doughnut, Scatter } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BusinessNavBar from "../../../Business/layouts/BusinessNavBar/BusinessNavBar";
import { fetchAllBusinessPageData } from "../../../Business/utils/FirebaseFunctions/realtimeDBFunctions";
import {
  extractEvaluaitionsIDFromURL,
  removePrivateAccessVaraible,
} from "../../../Business/utils/stringExtensionFunction";
import homePagePreloaderAnimation from "../../../Global/assets/LottieFiles/homePagePreloaderAnimation.json";
import PageHelmet from "../../../Global/components/PageHelmet/PageHelmet";
import PreloaderSection from "../../../Global/components/PreloaderSection/PreloaderSection";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import useFullscreen from "../../../Global/customHooks/useFullscreen";
import { fetchAllDocsAtL2Firestore } from "../../../Global/utils/firebaseFunctions/firestoreFunctions";
import {
  capitalizeFirstLetter,
  convertToTitleCase,
} from "../../../Global/utils/stringExtensionFunctions";
import EnhancedTable from "../../components/Tables/ScoresTable";
import {
  addNewAdminDataForCompany,
  getAllBusinessCandidatesList,
} from "../../utils/FirebaseFunctions/realtimeDBFunctions";
import { getSiteBusinessName } from "../../utils/stringExtensionFunction";
import "./TestResultsPage.css";

const TestResultsPage = () => {
  const { businessSlug } = useParams();
  const isTabletSize = useIsTabletSize();
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [thisTestCandidatesStatus, setThisTestCandidatesStatus] = useState({});
  const { user } = useSelector((state) => ({ ...state }));
  const { isFullscreen } = useFullscreen();
  // eslint-disable-next-line no-unused-vars
  const [businessName, setBusinessName] = useState("");
  const [allTestsData, setAllTestsData] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [newCandEmail, setNewCandEmail] = useState("");
  const [addCandLoading, setAddCandLoading] = useState(false);
  useEffect(() => {
    const getPageData = async () => {
      setIsLoading(true);
      const data = await fetchAllBusinessPageData({
        realTimeDBPath: `${capitalizeFirstLetter(
          businessSlug ?? getSiteBusinessName().split("-")[0]
        )}_all_pages_data`,
        categoryName: "landing_page",
      });
      console.log("DWaraka Siri all pages data: ", data);
      setPageData(data);
      // setIsLoading(false);
    };
    const getAllCandidatesData = async (pageTestSlug) => {
      console.log("DWaraka Siri pageTestSlug: ", pageTestSlug);
      setIsLoading(true);
      try {
        const allCandidatesObj = await getAllBusinessCandidatesList(
          `${busName.split("-")[0]}_candidates_list`
        );
        let emailUidArray = {};
        emailUidArray = Object.assign(
          {},
          ...allCandidatesObj["valid_users_mails"].map((item) => ({
            [item.email]: item.uid,
          }))
        );
        console.log(
          "Dwarkaa Siri allCandidatesObj: ",
          allCandidatesObj[pageTestSlug]
        );
        const statuses = removePrivateAccessVaraible(
          allCandidatesObj[pageTestSlug]
        ).reduce(
          (acc, curr) => {
            if (curr.status in acc) {
              acc[curr.status]++;
            } else {
              acc[curr.status] = 1;
            }
            return acc;
          },
          { Completed: 0, Unattempted: 0, Incomplete: 0 }
        );
        setThisTestCandidatesStatus(statuses);
        console.log("DWaraka Siri statuses: ", statuses);
        const data = await fetchAllBusinessPageData({
          realTimeDBPath: `${capitalizeFirstLetter(
            businessSlug ?? getSiteBusinessName().split("-")[0]
          )}_all_pages_data`,
          categoryName: pageTestSlug,
        });
        console.log("Dwaraka Siri data: ", data);

        let firstRoundName = "";
        if (data?.roundsData) {
          firstRoundName = Object.keys(data?.roundsData)[0];
        }

        const candidatesArray =
          removePrivateAccessVaraible(allCandidatesObj[pageTestSlug]) || [];
        const promises = candidatesArray.map((candidatesData) => {
          if (emailUidArray[candidatesData.email] && firstRoundName) {
            return fetchAllDocsAtL2Firestore({
              parent_collection: `${busName.split("-")[0]}${
                firstRoundName.startsWith("onlineTest")
                  ? "_online_test_submissions"
                  : firstRoundName === "codingRound"
                  ? "_coding_round_submissions"
                  : "_interview_submissions"
              }`,
              parent_document: emailUidArray[candidatesData.email],
              child_collection: pageTestSlug,
            }).then((response) => {
              if (response?.length > 0) {
                const updatedResponse = {
                  ...response[0], // Spread existing object properties
                  email: candidatesData.email, // Add new email property
                };
                return updatedResponse;
              } else {
                return { email: candidatesData.email };
              }
            });
          }
          return { email: candidatesData.email };
        });

        const candidatesInterviewSubmissions = await Promise.all(promises);
        const unAttemptedCandidatesList = candidatesInterviewSubmissions.filter(
          (item) => !item.id
        );

        const responseData = {
          unAttemptedCandidatesList,
          candidatesInterviewSubmissions: candidatesInterviewSubmissions.filter(
            (item_1) => item_1.id
          ),
          roundType: firstRoundName,
          testTitle: data?.title,
          testDescription: data?.description,
        };
        console.log("DWaraka Siri allResultsData: ", responseData);
        setAllTestsData(responseData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching questions:", error);
      }
    };

    let busName = capitalizeFirstLetter(businessSlug ?? getSiteBusinessName());
    setBusinessName(busName);
    if (user?.uid) {
      getPageData();
      getAllCandidatesData(
        extractEvaluaitionsIDFromURL(window.location.pathname)
      );
    }
  }, [user]);
  const addNewCand = async (e) => {
    e.preventDefault();
    setAddCandLoading(true);
    let realTimeDBPath1 = `${capitalizeFirstLetter(
      businessSlug ?? getSiteBusinessName().split("-")[0]
    )}_candidates_list/${extractEvaluaitionsIDFromURL(
      window.location.pathname
    )}`;
    let data1 = {
      email: newCandEmail,
      status: "Unattempted",
    };
    await addNewAdminDataForCompany({
      realTimeDBPath: realTimeDBPath1,
      newCandidate: data1,
    });
    let realTimeDBPath2 = `${capitalizeFirstLetter(
      businessSlug ?? getSiteBusinessName().split("-")[0]
    )}_candidates_list/valid_users_mails`;
    let data2 = {
      email: newCandEmail,
      uid: "",
    };
    await addNewAdminDataForCompany({
      realTimeDBPath: realTimeDBPath2,
      newCandidate: data2,
    });
    setNewCandEmail("");
    setAddCandLoading(false);
  };
  return (
    <div className="landing-page">
      <main className="landingPage">
        <PageHelmet
          title={"Business: Landing Page"}
          description={
            "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
          }
        />
        {!isFullscreen && !isLoading && (
          <BusinessNavBar
            sticky={true}
            useAnchorTag={false}
            restrictRedirection={false}
            landingPageData={pageData}
          />
        )}
        {isLoading ? (
          <PreloaderSection
            animationData={homePagePreloaderAnimation}
            textData={"Awakening your AI interview partner..."}
          />
        ) : (
          <div className="test-results-page">
            <div className="px-3 px-md-5 bg-blue-gray-50 pt-4 border-bottom border-blue-gray-100 graphs-container container-fluid">
              <div className="row">
                <div className="mb-4 col-lg-4 col-md-6">
                  <div className="h-100 border-blue-gray-100  card">
                    <div className="bg-white px-4 py-3 d-flex align-items-center text-blue-gray-700 border-blue-gray-100 ft-16 card-header">
                      <FontAwesomeIcon className="me-3" icon={faFileLines} />
                      <span className="text-blue-800 font-weight-500">
                        Test Overview
                      </span>
                      <Tooltip
                        title="This section displays the test details along with sum of total test-takers"
                        placement="top"
                        arrow
                        className="password-tooltip"
                        TransitionComponent={Zoom}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="px-2 ms-auto pointer-cursor password-tooltip"
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-column justify-content-center p-4 pt-1 my-1 ft-16 card-body">
                      <p className="text-center pb-1 mb-1">
                        {allTestsData.testTitle}
                      </p>
                      {/* <small className="text-center text-muted mb-2 pb-2">
                        {allTestsData.testDescription}
                      </small> */}

                      <div className="d-flex pb-4">
                        <div className="col-5">
                          <h5
                            className={`text-blue-gray-700 fw-normal ${
                              isTabletSize ? "text-lg mb-3" : "text-sm mb-1"
                            }`}
                          >
                            Total Test-Takers
                          </h5>
                          <span
                            className={`fw-300 text-purple-500 ${
                              isTabletSize ? "text-5xl mb-3" : "text-2xl mb-1"
                            }`}
                            title={1}
                          >
                            {allTestsData.unAttemptedCandidatesList.length +
                              allTestsData.candidatesInterviewSubmissions
                                .length}
                          </span>
                        </div>
                        <div className="vr vertical-divider text-blue-gray-300 me-4 ms-2" />
                        <div className="col-6">
                          <h5
                            className={`text-blue-gray-700 fw-normal ${
                              isTabletSize ? "text-lg mb-3" : "text-sm mb-1"
                            }`}
                          >
                            Test-Type
                          </h5>
                          <span
                            className={`fw-300 mt-4 text-blue-800 ${
                              isTabletSize ? "text-lg" : "text-sm"
                            } `}
                            title={1}
                          >
                            {convertToTitleCase(allTestsData.roundType)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="text-blue-gray-700 me-2">
                          Reports:
                        </span>
                        <span className="text-blue-800">
                          {allTestsData.candidatesInterviewSubmissions.length} /{" "}
                          {allTestsData.unAttemptedCandidatesList.length +
                            allTestsData.candidatesInterviewSubmissions.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 col-lg-4 col-md-6">
                  <div className="h-100 border-blue-gray-100  card">
                    <div className="bg-white px-4 py-3 d-flex align-items-center text-blue-gray-700 border-blue-gray-100 ft-16 card-header">
                      <FontAwesomeIcon className="me-3" icon={faChartPie} />
                      <span className="text-blue-800 font-weight-500">
                        Status Summary
                      </span>
                      <Tooltip
                        title="This section displays the summary of overall status
                        Completed- Test taker has submitted the test,
                         Unattempted- Test taker has not started the test,
                         Incomplete- Test taker has started but did not submit the test
                        "
                        placement="top"
                        arrow
                        className="password-tooltip"
                        TransitionComponent={Zoom}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="px-2 ms-auto pointer-cursor password-tooltip"
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-column justify-content-center ft-16 card-body">
                      <div className="d-flex pb-4">
                        <Doughnut
                          data={{
                            labels: ["Completed", "Unattempted", "Incomplete"],
                            datasets: [
                              {
                                data: [
                                  thisTestCandidatesStatus["Completed"],
                                  thisTestCandidatesStatus["Unattempted"],
                                  thisTestCandidatesStatus["Incomplete"],
                                ],
                                backgroundColor: [
                                  "rgba(75, 192, 192, 0.4)",
                                  "rgba(255, 99, 132, 0.4)",
                                  "rgba(255, 206, 86, 0.4)",
                                ],
                                borderColor: [
                                  "rgba(75, 192, 192, 1)",
                                  "rgba(255, 99, 132, 1)",
                                  "rgba(255, 206, 86, 1)",
                                ],
                                borderWidth: 1,
                              },
                            ],
                          }}
                          options={{
                            rotation: -90,
                            circumference: 180,
                            cutout: "60%",
                            maintainAspectRatio: false,
                            responsive: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 col-lg-4 col-md-6">
                  <div className="h-100 border-blue-gray-100  card">
                    <div className="bg-white px-4 py-3 d-flex align-items-center text-blue-gray-700 border-blue-gray-100 ft-16 card-header">
                      <FontAwesomeIcon className="me-3" icon={faChartBar} />
                      <span className="text-blue-800 font-weight-500">
                        Time Vs Score%
                      </span>
                      <Tooltip
                        title="This graph gives a a quick overview of test-taker performance. It is a scatter plot between overall score in percentages vs time taken in mins"
                        placement="top"
                        arrow
                        className="password-tooltip"
                        TransitionComponent={Zoom}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="px-2 ms-auto pointer-cursor password-tooltip"
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-column justify-content-center ft-16 card-body">
                      <div className="d-flex pb-4">
                        <Scatter
                          data={{
                            datasets: [
                              {
                                label: "Time Vs Score%",
                                data: allTestsData.candidatesInterviewSubmissions.map(
                                  (candidate) => ({
                                    x: Number(
                                      candidate.totalTimeTaken / 60
                                    ).toFixed(2),
                                    y: Number(
                                      candidate.overAllScorePercentage
                                    ).toFixed(2),
                                  })
                                ),
                                backgroundColor: "rgba(75, 192, 192, 0.2)",
                                borderColor: "rgba(75, 192, 192, 1)",
                              },
                            ],
                          }}
                          options={{
                            scales: {
                              xAxes: [
                                {
                                  type: "linear",
                                  position: "bottom",
                                  scaleLabel: {
                                    display: true,
                                    labelString: "Time Taken",
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  scaleLabel: {
                                    display: true,
                                    labelString: "Scores",
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" py-3 my-2">
              <div
                className={`my-4 row justify-content-center mx-1 mx-md-2 mx-lg-5`}
              >
                <EnhancedTable
                  rows={allTestsData.candidatesInterviewSubmissions || []}
                  roundType={allTestsData.roundType}
                />
              </div>
            </div>
            <div className="px-0 mx-auto px-md-5 bg-blue-gray-50 pt-4 border-bottom border-blue-gray-100 graphs-container container-fluid row">
              {allTestsData.unAttemptedCandidatesList.length > 0 && (
                <div className="mb-4 col-lg-4 col-md-6">
                  <div className="h-100 border-blue-gray-100  card">
                    <div className="bg-white px-4 py-3 d-flex align-items-center text-blue-gray-700 border-blue-gray-100 ft-16 card-header">
                      <FontAwesomeIcon className="me-3" icon={faUsersSlash} />
                      <span
                        className={`text-blue-800 font-weight-500 ${
                          isTabletSize ? "text-lg" : "text-sm"
                        }`}
                      >
                        Unattempted Candidates list
                      </span>
                      <Tooltip
                        title="This section displays the list of candidates who have not attempted the test"
                        placement="top"
                        arrow
                        className="password-tooltip"
                        TransitionComponent={Zoom}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="px-2 ms-auto pointer-cursor password-tooltip"
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-column justify-content-center p-4 pt-1 my-1 ft-16 card-body">
                      <div>
                        <ol>
                          {allTestsData.unAttemptedCandidatesList.map(
                            (candidate, index) => (
                              <li key={index}>{candidate.email}</li>
                            )
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {businessSlug && (
                <div className="mb-2 col-lg-4 col-md-6">
                  <div className="h-100 border-blue-gray-100 card">
                    <div className="bg-white px-4 py-3 d-flex align-items-center text-blue-gray-700 border-blue-gray-100 ft-16 card-header">
                      <FontAwesomeIcon className="me-3" icon={faUserPlus} />
                      <span className="text-blue-800 font-weight-500">
                        Add new candidates
                      </span>
                      <Tooltip
                        title="You can add new candidates to this test from this section"
                        placement="top"
                        arrow
                        className="password-tooltip"
                        TransitionComponent={Zoom}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="px-2 ms-auto pointer-cursor password-tooltip"
                        />
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-column justify-content-center p-4 pt-1 my-1 ft-16 card-body">
                      <form>
                        <div className="form-group first mb-1">
                          {!isEmailValid && (
                            <small className="text-danger">
                              (please enter a valid email)
                            </small>
                          )}
                          <input
                            onChange={(e) => {
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              if (!emailRegex.test(e.target.value)) {
                                setIsEmailValid(false);
                              } else {
                                setIsEmailValid(true);
                              }
                              setNewCandEmail(e.target.value);
                            }}
                            value={newCandEmail}
                            required
                            type="email"
                            className="form-control my-2"
                            id="email-address"
                            placeholder="your-email@gmail.com"
                          />
                        </div>
                        {console.log(
                          "DWaraka Siri ",
                          isEmailValid,
                          newCandEmail,
                          addCandLoading
                        )}
                        <button
                          disabled={
                            !isEmailValid || !newCandEmail || addCandLoading
                          }
                          onClick={(e) => addNewCand(e)}
                          type="submit"
                          defaultValue="Log In"
                          className="btn w-100 btn-primary mt-1"
                        >
                          Add Candidate
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default TestResultsPage;
