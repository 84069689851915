import { getAnalytics, logEvent } from "@firebase/analytics";
const analytics = getAnalytics();

export const logAnalyticsErrorEvent = ({ eventType, eventData }) => {
  logEvent(analytics, eventType, eventData);
};

export const eventTypes = {
  gpt_api_failure: "gpt_api_failure",
  video_proctoring_api_failure: "video_proctoring_api_failure",
  text_to_speech_api_failure: "text_to_speech_api_failure",
  interview_internal_failure: "interview_internal_failure",
  firebase_failure: "firebase_failure",
  firebase_success: "firebase_success",
};
