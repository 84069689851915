import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import "./ModalCloseButton.css";

export default function ModalCloseButton({ onModalClose }) {
  ModalCloseButton.propTypes = {
    onModalClose: PropTypes.func,
  };
  return (
    <button
      onClick={() => onModalClose()}
      className="mb-0 btn btn-outline-danger ms-auto modal-close-btn d-flex"
    >
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );
}
