import { Share } from "@mui/icons-material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PropTypes from "prop-types";
import * as React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import "./ShareSpeedDial.css";

const shareButtons = [
  {
    button: LinkedinShareButton,
    name: "LinkedIn",
    icon: LinkedInIcon,
    color: "#0072b1", // Specify the color
  },
  {
    button: TwitterShareButton,
    name: "Twitter",
    icon: TwitterIcon,
    color: "#00acee", // Specify the color
  },
  {
    button: WhatsappShareButton,
    name: "WhatsApp",
    icon: WhatsAppIcon,
    color: "#25d366", // Specify the color
  },
  {
    button: FacebookShareButton,
    name: "Facebook",
    icon: FacebookRoundedIcon,
    color: "#3b5998", // Specify the color
  },
];

export default function ShareSpeedDial({ shareUrl, shareTitle }) {
  ShareSpeedDial.propTypes = {
    shareUrl: PropTypes.string,
    shareTitle: PropTypes.string,
  };
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        height: 250,
        transform: "translateZ(0px)",
        flexGrow: 1,
        zIndex: 30,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        icon={<Share />}
        direction="down"
        opendirection="down"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        color="success"
        FabProps={{
          size: "small",
          color: "success",
        }}
        buttonprops={{ size: "small" }}
      >
        {shareButtons.map((shareButton) => (
          <SpeedDialAction
            sx={{ border: "2px solid #2f7c31" }}
            key={shareButton.name}
            icon={
              <shareButton.button url={shareUrl} title={shareTitle}>
                <shareButton.icon
                  sx={{ color: shareButton.color, fontSize: 32 }}
                />
              </shareButton.button>
            }
            tooltipTitle={shareButton.name}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
