/* eslint-disable no-unsafe-optional-chaining */
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fab } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import incomplete from "../../../assets/Images/incomplete.webp";

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { useSelector } from "react-redux";
import ShareSpeedDial from "../../../../Consumer/components/ShareSpeedDial/ShareSpeedDial";
import { formatTimestamp } from "../../../../Consumer/supportFunctions/GeneralHelperFunctions";
import { convertToTitleCase } from "../../../utils/stringExtensionFunctions";
import ImagePlayer from "../../ImagePlayer/ImagePlayer";
import CodingRoundReportDetailModal from "../../Modals/ReportsModals/CodingRoundReportDetailModal";
import "../ReportComponents.css";
import CodingReportQuestionsListItemComp from "./CodingReportQuestionsListItemComp";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
  ArcElement
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="w-80"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const polarArcOptions = {
  responsive: true,
  scales: {
    r: {
      angleLines: {
        display: true,
      },
      suggestedMin: 0,
      suggestedMax: 10,
    },
  },
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
  },
};

export default function ReportForCodingRoundComponent({
  codingReportData,
  codingRoundId,
  showShare,
  showBackBtn = false,
}) {
  ReportForCodingRoundComponent.propTypes = {
    codingReportData: PropTypes.any.isRequired,
    codingRoundId: PropTypes.any.isRequired,
    showShare: PropTypes.any.isRequired,
    showBackBtn: PropTypes.bool,
  };
  const { user } = useSelector((state) => ({ ...state }));
  const [dataSourceForModel, setDataSourceForModel] = useState([]);
  const [indexForModel, setIndexForModel] = useState(0);
  const [
    shouldOpenNonCodingRoundReviewModel,
    setShouldOpenNonCodingRoundReviewModel,
  ] = React.useState(false);

  useEffect(() => {
    console.log("Siri coding round report: ", codingReportData);
  }, []);

  const fabStyle = {
    backgroundColor: "rgb(220, 53, 69)",
    "&:hover": {
      backgroundColor: "rgb(187, 45, 59)",
    },
  };

  const calculateTotalScore = ({ data }) => {
    let totalScore =
      (data?.reduce(
        (accumulator, roundData) => accumulator + roundData.bestScore,
        0
      ) *
        10) /
      data?.length;
    totalScore = isNaN(totalScore) ? 0 : Number(totalScore).toFixed(2);
    return totalScore;
  };
  const formattedTimeStamps = formatTimestamp(codingReportData?.timestamp);
  const formattedDate = formattedTimeStamps.formattedDate;

  return (
    <div>
      <TabPanel
        className="reports_page"
        key={0}
        value={0}
        index={0}
        style={{ width: "100%" }}
      >
        <div className="d-flex justify-content-between mb-2 mt-0 align-items-center">
          {showShare && (
            <div style={{ height: "50px", zIndex: "20" }}>
              <ShareSpeedDial
                shareUrl={`https://evaluaitor.com/report/${user.profileId}/${
                  codingReportData.id || codingRoundId
                }`}
                shareTitle={
                  "I'm excited to showcase my AI mock interview results on the EvaluAItor website. Check them out at"
                }
              />
            </div>
          )}
          {showBackBtn && (
            <button
              className="close-round-btn"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Fab color="primary" aria-label="home" sx={fabStyle}>
                <FontAwesomeIcon icon={faArrowLeftLong} />
              </Fab>
            </button>
          )}
        </div>
        <div className=" text-center justify-content-evenly">
          {codingReportData?.isThisEntirelyDone ? (
            <div className="row text-center justify-content-evenly">
              <div>
                <div className="row text-center justify-content-evenly">
                  <div className="col-lg-6 col-sm-12 text-start mt-2">
                    <div className="flex flex-col gap-2">
                      <p className="report_card_heading d-block text-xs md:text-base">
                        <span
                          style={{
                            width: "150px",
                            display: "inline-block",
                          }}
                          className="font"
                        >
                          {" "}
                          Name:
                        </span>{" "}
                        {convertToTitleCase(user.displayName)}
                      </p>
                      <p className="report_card_heading d-block text-xs md:text-base">
                        <span
                          className="font"
                          style={{
                            width: "150px",
                            display: "inline-block",
                          }}
                        >
                          Round ID:{" "}
                        </span>{" "}
                        {codingReportData?.id || codingRoundId}
                      </p>
                      <p className="report_card_heading d-block text-xs md:text-base">
                        <span
                          className="font"
                          style={{
                            width: "150px",
                            display: "inline-block",
                          }}
                        >
                          Taken on:{" "}
                        </span>{" "}
                        {formattedDate}
                      </p>
                      <p className="report_card_heading d-block text-xs md:text-base">
                        <span
                          className="font"
                          style={{
                            width: "150px",
                            display: "inline-block",
                          }}
                        >
                          Score:
                        </span>{" "}
                        {calculateTotalScore({
                          data: codingReportData.allRoundsReportData,
                        })}
                        /{100}
                      </p>
                      <p className="report_card_heading d-block text-xs md:text-base">
                        <span
                          className="font"
                          style={{
                            width: "150px",
                            display: "inline-block",
                          }}
                        >
                          Questions asked:
                        </span>{" "}
                        {codingReportData.allRoundsReportData?.length || 0}
                      </p>
                    </div>
                    <div className="my-3 row d-flex justify-content-center align-items-center">
                      {codingReportData?.proctoringData?.length > 0 && (
                        <div
                          className={`my-3 d-flex justify-content-center align-items-start  ${
                            codingReportData?.recordedUrl
                              ? "col-lg-6"
                              : "col-lg-12"
                          }`}
                          style={{
                            transform:
                              codingReportData.proctoringData?.length > 0
                                ? "scale(0.7)"
                                : "scale(0.1)",
                          }}
                        >
                          <ImagePlayer
                            proctoringData={codingReportData?.proctoringData}
                          />
                        </div>
                      )}
                      {codingReportData.recordedUrl && (
                        <div
                          className={`my-3  ${
                            codingReportData.proctoringData?.length > 0
                              ? "col-lg-6"
                              : "col-lg-12"
                          }`}
                        >
                          <video
                            className="w-100"
                            src={codingReportData?.recordedUrl}
                            controls
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <div className="row d-flex align-items-center justify-content-center">
                      <div className=" col-8 pe-2">
                        <div>
                          <PolarArea
                            data={{
                              labels: Array.from(
                                {
                                  length:
                                    codingReportData?.allRoundsReportData
                                      ?.length,
                                },
                                (_, i) => `Question-${i + 1}`
                              ),
                              datasets: [
                                {
                                  label: "Score",
                                  data: codingReportData?.allRoundsReportData?.map(
                                    (roundData) => roundData?.bestScore
                                  ),
                                  backgroundColor: [
                                    "rgba(255, 99, 132, 0.5)",
                                    "rgba(75, 192, 192, 0.5)",
                                    "rgba(255, 205, 86, 0.5)",
                                    "rgba(201, 203, 207, 0.5)",
                                    "rgba(54, 162, 235, 0.5)",
                                    "rgba(18, 0, 157, 0.5)",
                                  ],
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            options={polarArcOptions}
                          />
                        </div>
                      </div>
                      <div
                        className="scoreCnt tooltipped score col-4"
                        data-position="bottom"
                        data-tooltip="Longest streak/Global longest streak"
                        style={{ color: "#5e6d9e" }}
                      >
                        <span
                          className="numerator"
                          style={{ color: "#5e6d9e", borderColor: "#5e6d9e" }}
                        >
                          {calculateTotalScore({
                            data: codingReportData.allRoundsReportData,
                          })}
                        </span>
                        <span className="denominator">100</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  border: "1px solid #EAEBEF",
                  margin: "20px 0px",
                }}
              />
              {codingReportData?.allRoundsReportData?.map(
                (roundData, index) => (
                  <CodingReportQuestionsListItemComp
                    key={roundData.questionId}
                    roundData={roundData}
                    index={index}
                    setIndexForModel={setIndexForModel}
                    setDataSourceForModel={setDataSourceForModel}
                    setShouldOpenNonCodingRoundReviewModel={
                      setShouldOpenNonCodingRoundReviewModel
                    }
                  />
                )
              )}
              {shouldOpenNonCodingRoundReviewModel &&
                CodingRoundReportDetailModal(
                  shouldOpenNonCodingRoundReviewModel,
                  setShouldOpenNonCodingRoundReviewModel,
                  indexForModel,
                  dataSourceForModel
                )}
            </div>
          ) : (
            <>
              <img
                src={incomplete}
                alt="Not incompleted"
                className="w-50 mt-4"
              />
            </>
          )}
        </div>
      </TabPanel>
    </div>
  );
}
