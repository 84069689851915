import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Fab } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { PolarArea } from "react-chartjs-2";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CertificateOfCompletion from "../../../../Consumer/components/CertificateOfCompletion/CertificateOfCompletion";
import ShareSpeedDial from "../../../../Consumer/components/ShareSpeedDial/ShareSpeedDial";
import { fetchPotdFromFireStore } from "../../../../Consumer/pages/ProblemOfTheDayPage/PotdSupportFunctions";
import { formatTimestamp } from "../../../../Consumer/supportFunctions/GeneralHelperFunctions";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import {
  convertToTitleCase,
  formatTimeToString,
} from "../../../utils/stringExtensionFunctions";
import { roundStatus } from "../../InterviewBot/InterviewBotConstants/InterviewBotConstants";
import AlgoReportProblemDetailsModal from "../../Modals/ReportsModals/AlgoReportProblemDetailsModal";
import InterviewReportDetailModal from "../../Modals/ReportsModals/InterviewReportDetailModal";
import "../ReportComponents.css";
import InterviewReportDSARoundsListItemComp from "./InterviewReportDSARoundsListItemComp";
import InterviewReportNonDSARoundsListItemComp from "./InterviewReportNonDSARoundsListItemComp";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
  ArcElement
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="w-80"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ReportForASingleInterviewComponent({
  interviewRecord,
  interViewId,
  showShare = false,
  shouldShowCertificate = false,
  showBackBtn = false,
}) {
  ReportForASingleInterviewComponent.propTypes = {
    interviewRecord: PropTypes.object.isRequired,
    interViewId: PropTypes.string,
    showShare: PropTypes.bool,
    shouldShowCertificate: PropTypes.bool,
    showBackBtn: PropTypes.bool,
  };
  const { user } = useSelector((state) => ({ ...state }));
  const childRef = useRef(null);
  const isTabletSize = useIsTabletSize();
  const [dataSourceForModel, setDataSourceForModel] = useState("");
  const [indexForModel, setIndexForModel] = useState(0);
  const [dataForAccordian, setDataForAccordian] = useState();
  const [showCertificate, setShowCertificate] = useState(false);
  const [shouldOpenModel, setShouldOpenModel] = React.useState(false);
  const [codelessRoundReviewsArray, setCodelessRoundReviewsArray] = useState(
    []
  );
  const [
    shouldOpenNonCodingRoundReviewModel,
    setShouldOpenNonCodingRoundReviewModel,
  ] = React.useState(false);
  const [dataForQuestions, setDataForQuestions] = useState([]);

  const formattedTimeStamps = formatTimestamp(interviewRecord?.timestamp);
  const formattedDateBig = formattedTimeStamps.formattedDateBig;
  const formattedDate = formattedTimeStamps.formattedDate;
  const fabStyle = {
    backgroundColor: "rgb(220, 53, 69)",
    "&:hover": {
      backgroundColor: "rgb(187, 45, 59)",
    },
  };
  const polarArcOptions = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };
  useEffect(() => {
    var newDataForQuestions = [];
    const fetchData = async () => {
      // Fetch data for the currently selected tab
      if (interviewRecord?.DSAQuestionsDataArray) {
        newDataForQuestions = await Promise.all(
          interviewRecord.DSAQuestionsDataArray.map(
            async (DSAQuestionsDataArrayItem) => {
              const potdQuestionDataFromId = await fetchPotdFromFireStore({
                parent_collection: "dSA_question_bank",
                parent_document: DSAQuestionsDataArrayItem.dsa_question_potdId,
              });
              const data = {
                dsa_question_potd_id:
                  DSAQuestionsDataArrayItem.dsa_question_potdId,
                dsa_question: potdQuestionDataFromId.question,
                evaluation_data: DSAQuestionsDataArrayItem.evaluation_data,
                userCode_data: DSAQuestionsDataArrayItem.userCode_data,
              };
              return {
                data,
                sourceIndex: 0,
                timestamp: interviewRecord.timestamp,
              }; // Store data along with source index
            }
          )
        );
      }
      setDataForQuestions(
        newDataForQuestions?.filter((item) => {
          return item && item.data !== null; // Return the item if it has non-null data
        })
      );
    };

    const normalRoundsArray = interviewRecord?.interviewRounds?.filter(
      (round) => round.roundType === "normal"
    );
    const normalRoundTitlesArray = normalRoundsArray?.map(
      (round) => `${round.roundTitle}RoundReview`
    );
    setCodelessRoundReviewsArray(normalRoundTitlesArray);
    fetchData();
  }, [interviewRecord, interViewId]);

  const handleDownloadCertificate = () => {
    toast.warning("Download in progress...");
    setShowCertificate(true);
    const loadingTimeout = setTimeout(async () => {
      if (childRef.current) {
        await childRef.current.handlePdfDownlod();
      }
    }, 1000); // Change the timeout value to match your actual loading time

    return () => {
      clearTimeout(loadingTimeout); // Clean up the timeout when the component unmounts
    };
  };

  return (
    <div>
      <AlgoReportProblemDetailsModal
        shouldOpenModel={shouldOpenModel}
        setShouldOpenModel={setShouldOpenModel}
        dataForAccordian={dataForAccordian}
      />
      {showCertificate ? (
        <>
          <CertificateOfCompletion
            ref={childRef}
            name={user.displayName}
            score={(interviewRecord.overallInterviewScore * 2).toFixed(2)}
            date={formattedDateBig}
            role={interviewRecord.sdeLevel}
            setShowCertificate={setShowCertificate}
          />
        </>
      ) : (
        <TabPanel
          className="reports_page"
          key={0}
          value={0}
          index={0}
          style={{ width: "100%" }}
        >
          <div className="d-flex justify-content-between mb-2 mt-0 align-items-center">
            {showShare && (
              <div style={{ height: "50px", zIndex: "20" }}>
                <ShareSpeedDial
                  shareUrl={`https://evaluaitor.com/report/${user.profileId}/${
                    interviewRecord.id || interViewId
                  }`}
                  shareTitle={
                    "I'm excited to showcase my AI mock interview results on the EvaluAItor website. Check them out at"
                  }
                />
              </div>
            )}
            {showBackBtn && (
              <button
                className="close-round-btn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Fab
                  size={isTabletSize ? "medium" : "small"}
                  color="primary"
                  aria-label="home"
                  sx={fabStyle}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </Fab>
              </button>
            )}
          </div>
          <div className="row text-center justify-content-evenly">
            <div className="col-lg-6 col-sm-12 text-start mt-2 flex flex-col gap-2">
              <p className="report_card_heading d-block text-xs md:text-base mt-2 mt-md-0">
                <span
                  style={{
                    width: isTabletSize ? "150px" : "120px",
                    display: "inline-block",
                  }}
                  className="font"
                >
                  {" "}
                  Name:
                </span>{" "}
                {convertToTitleCase(user.displayName)}
              </p>
              <p className="report_card_heading d-block text-xs md:text-base">
                <span
                  style={{
                    width: isTabletSize ? "150px" : "120px",
                    display: "inline-block",
                  }}
                  className="font"
                >
                  Interview ID:{" "}
                </span>{" "}
                {interviewRecord?.id || interViewId}
              </p>
              <p className="report_card_heading d-block text-xs md:text-base">
                <span
                  style={{
                    width: isTabletSize ? "150px" : "120px",
                    display: "inline-block",
                  }}
                  className="font"
                >
                  Taken on:{" "}
                </span>{" "}
                {formattedDate}
              </p>

              {!isNaN(interviewRecord?.totalTimeTaken) && (
                <p className="report_card_heading d-block text-xs md:text-base">
                  <span
                    style={{
                      width: isTabletSize ? "150px" : "120px",
                      display: "inline-block",
                    }}
                    className="font"
                  >
                    Time Taken:{" "}
                  </span>{" "}
                  {formatTimeToString(interviewRecord?.totalTimeTaken)}
                </p>
              )}
              <p className="report_card_heading d-block text-xs md:text-base">
                <span
                  style={{
                    width: isTabletSize ? "150px" : "120px",
                    display: "inline-block",
                  }}
                  className="font"
                >
                  Score:
                </span>{" "}
                {interviewRecord?.numberOfDSAQuestions
                  ? Number(interviewRecord?.overallInterviewScore).toFixed(2) ||
                    0
                  : codelessRoundReviewsArray
                      .map(
                        (roundKey) => interviewRecord[roundKey]?.averageScore
                      )
                      .filter((score) => typeof score === "number") // Filter out undefined values
                      .reduce((acc, score) => acc + score, 0) * 2 || 0}
                /{" "}
                {interviewRecord?.numberOfDSAQuestions
                  ? 10
                  : codelessRoundReviewsArray.length * 10}
              </p>
              {interviewRecord?.recordedUrl ? (
                <video
                  className="w-75"
                  src={interviewRecord.recordedUrl}
                  controls
                />
              ) : (
                !interviewRecord?.numberOfDSAQuestions && (
                  <>
                    <p className="report_card_heading d-block text-xs md:text-base">
                      <span
                        style={{
                          width: isTabletSize ? "150px" : "120px",
                          display: "inline-block",
                        }}
                        className="font"
                      >
                        No. of Rounds:
                      </span>{" "}
                      {codelessRoundReviewsArray.length}
                    </p>
                    <p className="report_card_heading d-block text-xs md:text-base">
                      <span
                        style={{
                          width: isTabletSize ? "150px" : "120px",
                          display: "inline-block",
                        }}
                        className="font"
                      >
                        Topics covered:
                      </span>{" "}
                      {codelessRoundReviewsArray
                        .map(
                          (roundKey) =>
                            interviewRecord[roundKey]?.question_rating?.length
                        )
                        .filter((score) => typeof score === "number") // Filter out undefined values
                        .reduce((acc, score) => acc + score, 0) || 0}
                    </p>
                    <p className="report_card_heading d-block text-xs md:text-base">
                      <span
                        style={{
                          width: isTabletSize ? "150px" : "120px",
                          display: "inline-block",
                        }}
                        className="font"
                      >
                        Questions asked:
                      </span>{" "}
                      {codelessRoundReviewsArray
                        .map(
                          (roundKey) =>
                            interviewRecord[roundKey]?.totalNumberOfQuestions
                        )
                        .filter((score) => typeof score === "number") // Filter out undefined values
                        .reduce((acc, score) => acc + score, 0) || 0}
                    </p>
                  </>
                )
              )}

              {interviewRecord?.OverAllInterviewStatus == "Completed" &&
                shouldShowCertificate && (
                  <p className="report_card_heading d-block text-start text-xs md:text-base mb-2 mb-md-1">
                    <button
                      className="report_card_heading text-decoration-none resume-evaluaitor-btn px-2 text-xs md:text-base"
                      onClick={handleDownloadCertificate}
                    >
                      <WorkspacePremiumIcon className="p-1 me-md-2 p-md-0 mb-1" />{" "}
                      Download Certifcate
                    </button>
                  </p>
                )}
            </div>
            {codelessRoundReviewsArray.length > 1 && (
              <div className=" col-lg-6 col-sm-12">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className=" col-8 pe-2">
                    <div>
                      <PolarArea
                        data={{
                          labels: [...codelessRoundReviewsArray].reverse(),
                          datasets: [
                            {
                              label: "Score",
                              data: [...codelessRoundReviewsArray]
                                ?.reverse()
                                ?.map((roundKey) => {
                                  return (
                                    interviewRecord[roundKey]?.averageScore * 2
                                  );
                                }),
                              backgroundColor: [
                                "rgba(255, 99, 132, 0.5)",
                                "rgba(75, 192, 192, 0.5)",
                                "rgba(255, 205, 86, 0.5)",
                                "rgba(201, 203, 207, 0.5)",
                                "rgba(54, 162, 235, 0.5)",
                                "rgba(18, 0, 157, 0.5)",
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={polarArcOptions}
                      />
                    </div>
                  </div>
                  <div
                    className="scoreCnt tooltipped score col-4"
                    data-position="bottom"
                    data-tooltip="Longest streak/Global longest streak"
                    style={{ color: "#5e6d9e" }}
                  >
                    <span
                      className="numerator"
                      style={{ color: "#5e6d9e", borderColor: "#5e6d9e" }}
                    >
                      {codelessRoundReviewsArray
                        ?.map(
                          (roundKey) => interviewRecord[roundKey]?.averageScore
                        )
                        .filter((score) => typeof score === "number") // Filter out undefined values
                        .reduce((acc, score) => acc + score, 0) * 2 || 0}
                    </span>
                    <span className="denominator">
                      {" "}
                      {interviewRecord?.numberOfDSAQuestions
                        ? 10
                        : codelessRoundReviewsArray.length * 10}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {codelessRoundReviewsArray?.map((roundKey) => (
              <div className=" col-lg-4 col-md-6 col-sm-12" key={roundKey}>
                <div className="score_card">
                  <div>
                    <div className="score_card_name">
                      {roundKey.replace("RoundReview", " Round")}
                    </div>
                    <div
                      className="row d-flex align-items-center justify-content-center"
                      style={{
                        opacity:
                          interviewRecord.interviewRounds.find(
                            (round) =>
                              `${round.roundTitle}RoundReview` === roundKey
                          )?.roundStatus === roundStatus.Completed
                            ? "1"
                            : "0.5",
                      }}
                    >
                      <div className=" col-8 pe-2">
                        <div>
                          <PolarArea
                            data={{
                              labels: interviewRecord[roundKey]
                                ? interviewRecord[
                                    roundKey
                                  ]?.question_rating.map((data) => data.topic)
                                : [],

                              datasets: [
                                {
                                  label: "Score",
                                  data: interviewRecord[roundKey]
                                    ? interviewRecord[
                                        roundKey
                                      ].question_rating.map(
                                        (data) => data.rating * 2
                                      )
                                    : [],
                                  backgroundColor: [
                                    "rgba(255, 99, 132, 0.5)",
                                    "rgba(75, 192, 192, 0.5)",
                                    "rgba(255, 205, 86, 0.5)",
                                    "rgba(201, 203, 207, 0.5)",
                                    "rgba(54, 162, 235, 0.5)",
                                    "rgba(18, 0, 157, 0.5)",
                                  ],
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            options={polarArcOptions}
                          />
                        </div>
                      </div>
                      <div
                        className="scoreCnt tooltipped score col-4"
                        data-position="bottom"
                        data-tooltip="Longest streak/Global longest streak"
                        style={{ color: "#5e6d9e" }}
                      >
                        <span
                          className="numerator"
                          style={{ color: "#5e6d9e", borderColor: "#5e6d9e" }}
                        >
                          {interviewRecord[roundKey]?.averageScore * 2 || 0}
                        </span>
                        <span className="denominator">10</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {interviewRecord?.numberOfDSAQuestions > 0 && (
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="score_card">
                  <div>
                    <div className="score_card_name">DSA Round</div>
                    <div
                      className="row"
                      style={{
                        opacity:
                          interviewRecord.interviewRounds.find(
                            (round) => round.roundTitle === "DSA"
                          )?.roundStatus === roundStatus.Completed
                            ? "1"
                            : "0.5",
                      }}
                    >
                      <div className=" col-8 pe-2 d-flex justify-content-center">
                        <div
                          className="CircularProgressbardiv"
                          style={{ width: "120px", height: "120px" }}
                        >
                          <div className="chart easyPieChart">
                            <CircularProgressbarWithChildren
                              background
                              styles={buildStyles({
                                pathColor: "#225e87",
                                textColor: "#f88",
                                trailColor: "#dbe9fe",
                                backgroundColor: "#dbe9fe",
                                backgroundPadding: "10px",
                              })}
                              value={interviewRecord.TotalDSAScore * 10 || 0}
                            >
                              <div className="text-center">
                                <img
                                  loading="lazy"
                                  height={95}
                                  src="https://media.geeksforgeeks.org/auth-dashboard-uploads/Group-12724.svg"
                                  alt="dsa-score-img"
                                />
                              </div>
                            </CircularProgressbarWithChildren>
                          </div>
                        </div>
                      </div>
                      <div
                        className="scoreCnt tooltipped score col-4"
                        data-position="bottom"
                        data-tooltip="Longest streak/Global longest streak"
                        style={{ color: "#225e87", borderColor: "#225e87" }}
                      >
                        <span
                          className="numerator"
                          style={{ color: "#225e87", borderColor: "#225e87" }}
                        >
                          {interviewRecord.TotalDSAScore * 1 || 0}
                        </span>
                        <span className="denominator">10</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <hr
            style={{
              border: "1px solid #EAEBEF",
              margin: "20px 0px",
            }}
          />
          {codelessRoundReviewsArray?.map((roundKey, index) => (
            <InterviewReportNonDSARoundsListItemComp
              roundKey={roundKey}
              key={roundKey}
              interviewRecord={interviewRecord}
              index={index}
              setIndexForModel={setIndexForModel}
              setDataSourceForModel={setDataSourceForModel}
              setShouldOpenNonCodingRoundReviewModel={
                setShouldOpenNonCodingRoundReviewModel
              }
            />
          ))}
          <hr
            style={{
              border: "1px solid #EAEBEF",
              margin: "20px 0px",
            }}
          />
          <InterviewReportDSARoundsListItemComp
            dataForQuestions={dataForQuestions}
            n={interviewRecord?.numberOfDSAQuestions}
            interviewRecord={interviewRecord}
            setDataForAccordian={setDataForAccordian}
            setShouldOpenModel={setShouldOpenModel}
          />
          {shouldOpenNonCodingRoundReviewModel &&
            InterviewReportDetailModal(
              shouldOpenNonCodingRoundReviewModel,
              setShouldOpenNonCodingRoundReviewModel,
              indexForModel,
              dataSourceForModel,
              interviewRecord
            )}
        </TabPanel>
      )}
    </div>
  );
}
