import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faCircleInfo,
  faFileContract,
  faPowerOff,
  faShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import userAvatar from "../../../Global/assets/Images/svgs/userAvatar.svg";
import CustomLinkTag from "../../../Global/components/CustomLinkTag/CustomLinkTag";
import FullscreenExitModal from "../../../Global/components/Modals/ExitFullscreenModal/ExitFullscreenModal";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import { getCompanyDomainTitle } from "../../../Global/utils/stringExtensionFunctions";
import { logAnalyticsClickEvent, logout } from "../../utils/authFunctions";
import "./BusinessNavBar.css";

export default function BusinessNavBar({
  sticky = false,
  restrictRedirection = false,
  useAnchorTag = false,
  landingPageData = {},
}) {
  let dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const [navFixed, setnavFixed] = useState(false);
  const [showLogoutAlertModal, setShowLogoutAlertModal] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));
  const isTabletSize = useIsTabletSize();

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, []);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setnavFixed(true);
    } else {
      setnavFixed(false);
    }
  };

  return (
    <div className={`${(navFixed || sticky) && "is-sticky"}`}>
      <Navbar expand="lg" className="main-navbar-container" fixed="top">
        <div className="tyn-appbar-wrap justify-content-between d-flex container">
          <Navbar.Brand
            className="tyn-appbar-logo"
            onClick={() => logAnalyticsClickEvent("logo_navbar")}
          >
            <CustomLinkTag
              classNames="text-decoration-none"
              restrictRedirection={restrictRedirection}
              useAnchorTag={useAnchorTag}
              to="/"
            >
              <div className="tyn-logo">
                {(navFixed || sticky) && isTabletSize ? (
                  <img
                    loading="lazy"
                    alt="nav-bar-logo rounded"
                    src={landingPageData.logo}
                    className={`px-3 tyn-logo-img ${
                      navFixed || sticky ? "visible" : ""
                    }`}
                  />
                ) : (
                  <h2>{getCompanyDomainTitle(landingPageData.companyName)}</h2>
                )}
              </div>
            </CustomLinkTag>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="tyn-appbar-content">
            <Nav className="ms-auto tyn-appbar-nav pe-5">
              <NavDropdown
                className="nav-dropdown"
                title={
                  <div className="d-flex align-items-center">
                    <span
                      className="pe-2"
                      onClick={() =>
                        logAnalyticsClickEvent("display_name_navbar")
                      }
                    >
                      {user?.displayName}
                    </span>
                    <span className="tyn-media tyn-size-lg">
                      <img
                        loading="lazy"
                        onClick={() =>
                          logAnalyticsClickEvent("profile_pic_navbar")
                        }
                        className="tyn-circle"
                        src={
                          Object.keys(user).length != 0
                            ? user.profilepic
                            : userAvatar
                        }
                        alt="nav-user-avatar"
                      />
                    </span>
                  </div>
                }
                id="basic-nav-dropdown"
              >
                {Object.keys(user).length != 0 && (
                  <>
                    <span className="dropdown-gap nav-dropdown-static-text">
                      <div className="tyn-media-group">
                        <div className="tyn-media tyn-size-lg">
                          <img
                            loading="lazy"
                            src={user ? user.profilepic : userAvatar}
                            alt="nav-bar-avatar"
                          />
                        </div>
                        <div className="tyn-media-col">
                          <div className="tyn-media-row">
                            <h6 className="name "> {user.displayName}</h6>
                          </div>
                          {user.email ? (
                            <div className="tyn-media-row has-dot-sap">
                              <p className="content ">{user.email}</p>
                            </div>
                          ) : (
                            <div className="tyn-media-row has-dot-sap">
                              <p className="content ">+{user.phoneNumber}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </span>
                    <ul className="m-0 p-0">
                      <li className="dropdown-divider" />
                    </ul>
                    <span
                      className="dropdown-gap nav-dropdown-static-text"
                      onClick={() =>
                        logAnalyticsClickEvent("subscription_navbar")
                      }
                    >
                      <div className="tyn-media-group">
                        <div className="tyn-media tyn-size-lg">
                          <img
                            loading="lazy"
                            className="subscription-img-logo rounded"
                            src={landingPageData.logo}
                            alt="nav-bar-avatar"
                          />
                        </div>
                        <div className="tyn-media-col">
                          <div className="tyn-media-row">
                            <h6 className="name ">
                              {landingPageData.companyName}
                            </h6>
                          </div>
                          <div className="has-dot-sap">
                            <p className="content ">
                              {landingPageData.companyEmail}
                            </p>
                          </div>
                        </div>
                      </div>
                    </span>
                    <ul className="m-0 p-0">
                      <li className="dropdown-divider" />
                    </ul>
                    {landingPageData.showDashboard && (
                      <>
                        <CustomLinkTag
                          restrictRedirection={restrictRedirection}
                          useAnchorTag={useAnchorTag}
                          onClick={() =>
                            logAnalyticsClickEvent("dashboard_navbar")
                          }
                          to={`/user-dashboard/${user.profileId}`}
                          classNames="text-decoration-none dropdown-item"
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            className=" nav-dropdown-icon"
                          />
                          <span>Dashboard</span>
                        </CustomLinkTag>
                        <ul className="m-0 p-0">
                          <li className="dropdown-divider" />
                        </ul>
                      </>
                    )}
                  </>
                )}
                {landingPageData.showFaq && (
                  <CustomLinkTag
                    restrictRedirection={restrictRedirection}
                    useAnchorTag={useAnchorTag}
                    onClick={() => logAnalyticsClickEvent("faq_navbar")}
                    to="/faq"
                    classNames="text-decoration-none dropdown-item"
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className=" nav-dropdown-icon"
                    />
                    <span>FAQ</span>
                  </CustomLinkTag>
                )}
                {landingPageData.showTnC && (
                  <CustomLinkTag
                    restrictRedirection={restrictRedirection}
                    useAnchorTag={useAnchorTag}
                    onClick={() => logAnalyticsClickEvent("tnc_navbar")}
                    to="/tnc"
                    classNames="text-decoration-none dropdown-item"
                  >
                    <FontAwesomeIcon
                      icon={faFileContract}
                      className=" nav-dropdown-icon"
                    />
                    <span>Terms</span>
                  </CustomLinkTag>
                )}
                {landingPageData.showPolicy && (
                  <CustomLinkTag
                    restrictRedirection={restrictRedirection}
                    useAnchorTag={useAnchorTag}
                    onClick={() => logAnalyticsClickEvent("privacy_navbar")}
                    to="/privacy"
                    classNames="text-decoration-none dropdown-item"
                  >
                    <FontAwesomeIcon
                      icon={faShield}
                      className=" nav-dropdown-icon"
                    />
                    <span>Privacy</span>
                  </CustomLinkTag>
                )}
                {(landingPageData.showPolicy ||
                  landingPageData.showTnC ||
                  landingPageData.showFaq) && (
                  <ul className="m-0 p-0">
                    <li className="dropdown-divider" />
                  </ul>
                )}
                {Object.keys(user).length != 0 ? (
                  <CustomLinkTag
                    restrictRedirection={restrictRedirection}
                    useAnchorTag={useAnchorTag}
                    onClick={() => {
                      setShowLogoutAlertModal(true);
                    }}
                    classNames="text-decoration-none dropdown-item"
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      className=" nav-dropdown-icon"
                    />
                    <span>Log Out</span>
                  </CustomLinkTag>
                ) : (
                  <CustomLinkTag
                    restrictRedirection={restrictRedirection}
                    useAnchorTag={useAnchorTag}
                    to="/login"
                    classNames="text-decoration-none dropdown-item"
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      className=" nav-dropdown-icon"
                    />
                    <span>Login</span>
                  </CustomLinkTag>
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {showLogoutAlertModal && (
        <FullscreenExitModal
          show={showLogoutAlertModal}
          onReject={() => setShowLogoutAlertModal(false)}
          onConfirm={() => logout({ dispatch, navigate, location })}
          text={"Are you sure you want to log out?"}
          heading={"Logout?"}
          confirmBtnText={"Logout"}
          rejectBtntext={"Cancel"}
        />
      )}
    </div>
  );
}
BusinessNavBar.propTypes = {
  sticky: PropTypes.bool,
  restrictRedirection: PropTypes.bool,
  useAnchorTag: PropTypes.bool,
  landingPageData: PropTypes.object.isRequired,
};
