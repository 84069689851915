import FeedIcon from "@mui/icons-material/Feed";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import React from "react";
import { roundStatus } from "../../InterviewBot/InterviewBotConstants/InterviewBotConstants";
export default function InterviewReportNonDSARoundsListItemComp({
  roundKey,
  index,
  interviewRecord,
  setIndexForModel,
  setDataSourceForModel,
  setShouldOpenNonCodingRoundReviewModel,
}) {
  return (
    <div
      className="css-xkn2kk-QuestionItemContainer e5i1odf7 reports-dsa-quesion-box p-1 px-md-2 px-lg-3 py-lg-2"
      style={{
        opacity:
          interviewRecord.interviewRounds.find(
            (round) => `${round.roundTitle}RoundReview` === roundKey
          )?.roundStatus === roundStatus.Completed
            ? "1"
            : "0.5",
      }}
    >
      {console.log("DWaraka Siri interviewRecord: ", interviewRecord)}
      <FeedIcon
        sx={{ color: index % 2 == 0 ? "#5e6d9e" : "#ae866d" }}
        className="p-1 me-md-2 p-md-0"
      />
      <div className="css-1n4xofq-QuestionItemInfo e5i1odf9">
        <span className="css-12tgxhh-QuestionLink e5i1odf11 text-xs md:text-base">
          {roundKey.replace("RoundReview", " round feedback")}
        </span>
      </div>
      <div className="css-1n4xofq-QuestionItemInfo e5i1odf9">
        <span className="css-12tgxhh-QuestionLink e5i1odf11 text-xs md:text-base">
          Score:
          {isNaN(parseFloat(interviewRecord[roundKey]?.averageScore * 2))
            ? 0
            : (
                parseFloat(interviewRecord[roundKey]?.averageScore * 2) * 1
              ).toFixed(1)}
        </span>
      </div>
      {interviewRecord.interviewRounds.find(
        (round) => `${round.roundTitle}RoundReview` === roundKey
      )?.roundStatus === roundStatus.Completed ? (
        <div className="css-cwdo40-QuestionItemDetails e5i1odf10 text-xs md:text-base ms-1">
          <span
            type="button"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            data-question-idx="0"
            style={{ color: "#5e6d9e" }}
            onClick={() => {
              setIndexForModel(index);
              setDataSourceForModel(roundKey);
              setShouldOpenNonCodingRoundReviewModel(true);
            }}
          >
            Details
          </span>
        </div>
      ) : (
        <Chip
          variant="outlined"
          color="warning"
          size="small"
          label="Incomplete"
        />
      )}
    </div>
  );
}
InterviewReportNonDSARoundsListItemComp.propTypes = {
  roundKey: PropTypes.string.isRequired,
  interviewRecord: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setIndexForModel: PropTypes.func.isRequired,
  setDataSourceForModel: PropTypes.func.isRequired,
  setShouldOpenNonCodingRoundReviewModel: PropTypes.func.isRequired,
};
