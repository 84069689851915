import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../Global/utils/stringExtensionFunctions";
import { getSiteBusinessName } from "../utils/stringExtensionFunction";

// Custom hook to fetch dynamic page data based on the URL
export function useDynamicPageData(dataPath, defaultData) {
  const [dynamicData, setDynamicData] = useState(defaultData);

  useEffect(() => {
    async function fetchData() {
      try {
        const businessName = capitalizeFirstLetter(getSiteBusinessName());
        const { default: dynamicData } = await import(
          `../data/WebsiteData/${businessName}/${dataPath}`
        );
        setDynamicData(dynamicData);
      } catch (error) {
        console.error(`Error fetching dynamic data for ${dataPath}:`, error);
        // Optionally handle error
      }
    }

    fetchData();
  }, [dataPath]);

  return dynamicData;
}
