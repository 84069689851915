import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { formatSeconds } from "../../../Global/utils/stringExtensionFunctions";
const RemainingTimeCounter = ({ startTime, endTime }) => {
  const [status, setStatus] = useState("");
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const startDateTime = new Date(startTime);
      const endDateTime = new Date(endTime);

      // Check if current time is before start time
      if (currentTime < startDateTime) {
        setStatus("Countdown");
        const timeDifference = startDateTime - currentTime;
        const seconds = Math.floor((timeDifference / 1000) % 60);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        setRemainingTime({ days, hours, minutes, seconds });
      }
      // Check if current time is after end time
      else if (currentTime > endDateTime) {
        setStatus("Expired");
        setRemainingTime(null);
      }
      // Current time is between start and end time
      else {
        setStatus("Active");
        const timeDifference = endDateTime - currentTime;
        const seconds = Math.floor((timeDifference / 1000) % 60);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        setRemainingTime({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime, endTime]);

  if (status === "Countdown" && remainingTime && remainingTime.days > 0) {
    return (
      <p className="text-primary mb-0 d-inline">
        Starts in{" "}
        <strong>
          {remainingTime.days} {remainingTime.days > 1 ? "days" : "day"}
        </strong>
      </p>
    );
  } else if (status === "Countdown") {
    return (
      <p className="text-primary mb-0 d-inline">
        Starts in:{" "}
        <strong>
          {remainingTime.hours}: {formatSeconds(remainingTime.minutes)}:{" "}
          {formatSeconds(remainingTime.seconds)}{" "}
        </strong>
      </p>
    );
  } else if (status === "Active" && remainingTime && remainingTime.days > 0) {
    return (
      <p className="text-success mb-0 d-inline">
        Ends in{" "}
        <strong>
          {remainingTime.days} {remainingTime.days > 1 ? "days" : "day"}
        </strong>
      </p>
    );
  } else if (status === "Active") {
    return (
      <p className="text-success mb-0 d-inline">
        Ends in:{" "}
        <strong>
          {remainingTime.hours > 0 && `${remainingTime.hours}:`}{" "}
          {remainingTime.minutes > 0 &&
            `${formatSeconds(remainingTime.minutes)}:`}{" "}
          {formatSeconds(remainingTime.seconds)}
        </strong>
      </p>
    );
  } else if (status === "Expired") {
    return <p className="text-danger mb-0 d-inline">The test has expired</p>;
  } else {
    return "Time left...";
  }
};

export default RemainingTimeCounter;
RemainingTimeCounter.propTypes = {
  startTime: PropTypes.instanceOf(Date).isRequired,
  endTime: PropTypes.instanceOf(Date).isRequired,
};
