export function getSiteBusinessName() {
  // Get the hostname from the current URL
  const hostname = window.location.hostname;

  // Split the hostname by '.'
  const parts = hostname.split(".");

  // Extract the first part
  var businessName = parts[0];
  if (businessName === "localhost") {
    businessName = "business";
  }
  return businessName;
}
export function extractEvaluaitionsIDFromURL(url) {
  // console.log("Dwaraka Siri url: ", url);
  // Check if the URL contains "/evluaitions/"
  if (url.includes("/evaluaitions/")) {
    // Split the URL by "/"
    const parts = url.split("/");
    // console.log("Dwaraka Siri parts: ", parts);

    // Find the index of "evluaitions"
    const index = parts.indexOf("evaluaitions");
    // console.log("Dwaraka Siri index: ", index);

    // If "evluaitions" exists in the URL
    if (index !== -1) {
      // console.log("Dwaraka Siri  parts[index + 1]: ", parts[index + 1]);

      // Return the string after "evaluaitions/"
      return parts[index + 1];
    }
  }
  // If "/evaluaitions/" is not found or the URL doesn't follow the expected pattern
  return null;
}
export const removePrivateAccessVaraible = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (!obj || typeof obj !== "object") {
    return [];
  }
  // eslint-disable-next-line no-unused-vars
  const { isPrivateAccess, ...rest } = obj;
  return Object.values(rest);
};
export const arrayToObjectWithPrivateAccess = (array, isPrivateAccess) => {
  const result = array.reduce((acc, item, index) => {
    acc[index] = item;
    return acc;
  }, {});
  if (isPrivateAccess != null) {
    result.isPrivateAccess = isPrivateAccess;
  }
  return result;
};
